//ClassDetails.js
import React from "react";
import styled from "styled-components";
import Approved from "../../components/StatusIcons/Approved";
import Pending from "../../components/StatusIcons/Pending";
import Alert from "../../components/StatusIcons/Alert";

function getStatusComponent(statusCode) {
  switch (statusCode) {
    case "approved":
      return Approved;
    case "pending":
      return Pending;
    case "alert":
      return Alert;
    default:
      return null; // Consider handling unknown statuses explicitly
  }
}

export const ClassCard = ({ classInfo, status }) => {
  const StatusComponent = getStatusComponent(status);

  return (
    <CardContent>
      <ClassInfoWrapper>
        <ClassTitle style={{ color: classInfo.classColor }}>
          {classInfo.classNumber}
        </ClassTitle>

        {/* Wrap the StatusComponent in the StatusIconWrapper for consistent styling */}
        {StatusComponent && (
          <StatusIconWrapper>
            {React.createElement(StatusComponent)}
          </StatusIconWrapper>
        )}

        <ClassDetailsStyled>
          {classInfo.details.map((detail, index) => (
            <DetailItem key={index}>
              <DetailLabel>{detail.label}</DetailLabel>
              <DetailValue>{detail.value}</DetailValue>
            </DetailItem>
          ))}
        </ClassDetailsStyled>
      </ClassInfoWrapper>
    </CardContent>
  );
};

export const NotificationStatusC = () => {
  const classData = [
   {
      classNumber: "A067 EmpireOrange-ClassTitleCSS",
      classColor: "orange",
      details: [
        {
          label: "學生",
          value: "Ben Chan",
        },
        {
          label: "費用",
          value: "HKD$2500",
        },
        { label: "交易日期", value: "13/07/2024" },
        { label: "付款方式", value: "轉數快" },
      ],
      status: "alert", //STATUS: pending, approved, alert
    },
    {
      classNumber: "A017 EmpireBlack-ClassTitleCSS",
      classColor: "black",
      details: [
        {
          label: "學生",
          value: "Peter Chan",
        },
        {
          label: "費用",
          value: "HKD$4500",
        },
        { label: "交易日期", value: "15/07/2024" },
        { label: "付款方式", value: "PayMe" },
      ],
      status: "pending", //STATUS: pending, approved, alert
    },
    // Add another class object here
    {
      classNumber: "A018 EmpireGold-ClassTitleCSS",
      classColor: "rgb(218,165,32)",
      details: [
        {
          label: "學生",
          value: "Alice Wong",
        },
        {
          label: "費用",
          value: "HKD$3000",
        },
        { label: "交易日期", value: "20/07/2024" },
        { label: "付款方式", value: "銀行轉帳" },
      ],
      status: "approved", //STATUS: pending, approved, alert
    },
    // You can add more objects as needed
  ];

  return (
    <>
      {classData.map((classInfo, index) => (
        <ClassCard
          key={index}
          classInfo={classInfo}
          status={classInfo.status}
        />
      ))}
    </>
  );
};
export default NotificationStatusC;

//STYLED-COMPONENTS:

export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
`;

export const ClassInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid #fff;
  border-radius: 0 0 20px 20px;
  width: 95%;
`;

export const ClassTitle = styled.h1`
  width: 95%;
  height: auto;
  font: 700 20px Poppins, sans-serif;
  text-align: center;
  margin-bottom: 0px;
`;

export const StatusIconWrapper = styled.div`
  /* border: 5px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const ClassDetailsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0em;
  width: 90%;
  padding-bottom: 0.5em;
`;

export const DetailItem = styled.div`
  display: contents; // Makes the container behave like a direct child of the grid container
`;

export const DetailLabel = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0c729e;
  margin-bottom: 5px;
`;

export const DetailValue = styled.span`
  font-family: Lora, sans-serif;
  color: #0c729e;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  font-style: italic;
`;
