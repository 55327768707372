import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LogintemplateRoot, HeaderTitleC } from "./LoginStyles";

const SuccessfulC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);
  const radius = 18;
  const circumference = radius * 2 * Math.PI;
  const [offset, setOffset] = useState(0); // Initialize offset to 0 for countdown effect

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(intervalId);
          navigate("/");
          return 0;
        }
        const newOffset = ((5 - (prevCountdown - 1)) / 5) * circumference;
        setOffset(newOffset);
        return prevCountdown - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [navigate, circumference]);

  return (
    <LogintemplateRoot
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <HeaderTitleC>註冊完成！</HeaderTitleC>
      <svg
        width="100"
        height="100"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="20"
          cy="20"
          r={radius}
          stroke="lightgrey"
          strokeWidth="2"
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform="rotate(-90 20 20)"
        />
        <circle
          cx="20"
          cy="20"
          r={radius}
          stroke="lightblue"
          strokeWidth="2"
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          transform="rotate(-90 20 20)"
          style={{ transition: "stroke-dashoffset 1s linear" }}
        />
        <text
          x="20"
          y="13"
          fill="gray"
          fontSize="25"
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ transform: "translateY(0.35em)" }} // Adjust text vertical alignment
        >
          {countdown}
        </text>
      </svg>

      <div>將在 {countdown} 秒内載入到主頁...</div>
    </LogintemplateRoot>
  );
};

export default SuccessfulC;
