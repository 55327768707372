//ClassDetails.js
import React, { useEffect, useState, useRef, useContext  } from "react";
import styled from "styled-components";
import Approved from "../../components/StatusIcons/Approved";
import Pending from "../../components/StatusIcons/Pending";
import Alert from "../../components/StatusIcons/Alert";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import api from '../../Api'; // adjust the path based on where api.js is located

function getStatusComponent(statusCode,orderId) {
  switch (statusCode) {
    case 1:
      return <Approved orderId={orderId} />;
    case 0:
      return <Pending orderId={orderId}/> ;
    case "alert":
      return <Alert orderId={orderId}/> ;
    default:
      return null; // Consider handling unknown statuses explicitly
  }
}

export const BookingApproved = () => {

const { currentUser, loading } = useContext(AuthContext);
const [data, setData] = useState([]);

  useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/orders/getOrders/`,{
            withCredentials: true,
        }
				);

				setData(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();

	}, []);



  return (

   <>
      {data?.map((classInfo, index) => (
        <ClassCard
          key={index}
          classInfo={classInfo}
          status={classInfo.status}
        />
      ))}
    </>
   
  );
};

export const ClassCard = ({ classInfo, status }) => {

const orderDetails = JSON.parse(classInfo.order_details);

const StatusComponent = getStatusComponent(status, classInfo.id);

console.log(classInfo)

return(
  <>
   <CardContent>
        <ClassInfoWrapper>
          <ClassTitle>
            {orderDetails.courseCode}
          </ClassTitle>
          <ClassTitle>
             {orderDetails.programName}
            {orderDetails.option1Value}
            </ClassTitle>

          {/* Wrap the StatusComponent in the StatusIconWrapper for consistent styling */}
          {StatusComponent && (
            <StatusIconWrapper>
                        {StatusComponent}
            </StatusIconWrapper>
          )}

          <ClassDetailsStyled>
              <DetailItem>
                <DetailLabel>Order ID:</DetailLabel>
                <DetailValue>2024-{classInfo.id}</DetailValue>
              </DetailItem>
                 <DetailItem>
                <DetailLabel>Student:</DetailLabel>
                <DetailValue>{classInfo.firstName} {classInfo.lastName}</DetailValue>
              </DetailItem>
                <DetailItem>
                <DetailLabel>Total price:</DetailLabel>
                <DetailValue>HK${classInfo.price}</DetailValue>
              </DetailItem>
                <DetailItem>
                <DetailLabel>Order date:</DetailLabel>
                <DetailValue>{classInfo.order_date}</DetailValue>
              </DetailItem>
          </ClassDetailsStyled>
        </ClassInfoWrapper>
      </CardContent>
    </>


        )
}



export default BookingApproved;

//STYLED-COMPONENTS:
export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ClassInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid #fff;
  border-radius: 0 0 20px 20px;
  width: 95%;
  margin-bottom: 20px; //space the cards out
`;

export const ClassTitle = styled.h1`
  width: 95%;
  height: auto;
  font: 700 20px Poppins, sans-serif;
  text-align: center;
  margin-bottom: 0px;
`;

export const StatusIconWrapper = styled.div`
  /* border: 5px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const ClassDetailsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0em;
  width: 90%;
  padding-bottom: 0.5em;
`;

export const DetailItem = styled.div`
  display: contents; // Makes the container behave like a direct child of the grid container
`;

export const DetailLabel = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0c729e;
  margin-bottom: 5px;
`;

export const DetailValue = styled.span`
  font-family: Lora, sans-serif;
  color: #0c729e;
  font-weight: 500;
  font-size: 16px;
  // text-decoration: underline;
  font-style: italic;
`;
