// TempNavBarDir.js
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import NavLinkList from "./NavLinkList"; // Make sure the import path matches your file structure

// import { navLinks } from "../../NavLinks"; // Original import path
import { navLinksLeft } from "../../AppNavLinks"; // NOTE the new filename and path for convenience to enter navLinks

import { navLinksRight } from "../../AppNavLinks"; // NOTE the new filename and path for convenience to enter navLinks

function TempNavBarDir() {
  const [open, setOpen] = useState(false);
  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <div ref={menuRef}>
        <Burger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </Burger>
        <Sidebar open={open} onClick={() => setOpen(!open)}>
          <ul>
            <Wrapper>
              <BoxLeft>
                <Title>Site Directory</Title>
                {/* Pass the imported navLinks to NavLinkList */}
                <NavLinkList links={navLinksLeft} />
              </BoxLeft>
              <BoxRight>
                <Title>TEMPLATES & COMPONENTS </Title>
                <NavLinkList links={navLinksRight} />
              </BoxRight>
            </Wrapper>
          </ul>
        </Sidebar>
      </div>
    </>
  );
}

export default TempNavBarDir;

export const Wrapper = styled.div`
  display: flex;
`;

export const Title = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
`;

//BoxLeft
export const BoxLeft = styled.div`
  width: 80%;
  height: 100vh;
  margin: 5px;
  padding: 5px;
`;

//BoxRight
export const BoxRight = styled.div`
  width: 50%;
  height: 100vh;
  margin: 5px;
  padding: 5px;
`;

export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.1s ease-in-out;
  height: 100vh;
  width: 50vw;
  padding-top: 3rem;
  background-color: #455a64;
  z-index: 99999999999999 !important;

  ul {
    margin-left: 1em;
    padding: 0;
  }

  li {
    list-style: none;
    color: #fff;
  }

  a {
    font-size: 11px;
    text-decoration: none;
    color: #fff;
  }
`;

//BURGER
export const Burger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 1rem;
  left: 50%;
  z-index: 9999999999999 !important;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? "#ccc" : "#455a64")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(-180)")};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateY(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(-180)")};
    }
  }
`;
