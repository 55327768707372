import React from "react";
import { TitleHeaderC } from "../../globalStyles"; //CHINESE TITLE
import MyProfileC from "./MyProfileC";
const UsersC = () => {
  return (
    <>
      <TitleHeaderC>個人資料</TitleHeaderC> 
      <MyProfileC />
    </>
  );
};

export default UsersC;