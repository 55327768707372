// src/components/GlobalStylesTester/GlobalStylesTester.jsx
import React from "react";

import ButtonMobile from "./ButtonMobile/ButtonMobile";
import ButtonBack from "./ButtonMobile/ButtonBack";
const GlobalStylesTester = () => {
  return (
    <>
      {/* *******************************************
       *** BUTTON TESTER ***
       ******************************************* */}

      <p>JOIN CLASS BUTTON:</p>
      <ButtonMobile>JOIN THE CLASS!</ButtonMobile>

      <p>Back Button</p>
      <ButtonBack />

      {/* *******************************************
       *** Font Tester ***
       ******************************************* */}
      <div
        style={{
          fontFamily: "var(--font-poppins)",
          padding: "var(--padding-base)",
        }}
      >
        <div
          style={{
            fontFamily: "var(--font-lora)",
            padding: "var(--padding-base)",
          }}
        >
          <h1>Lora Font Title</h1>
        </div>

        <div
          style={{
            border: "solid 1px var(--color-silver)",
            fontFamily: "var(--font-noto-sans-tc)",
            padding: "var(--padding-base)",
            color: "var(--color-gray)",
          }}
        >
          常休着元童害回祖喝汁快男免珠回
        </div>

        <br />

        <h1 style={{ color: "var(--color-steelblue-100)" }}>
          Heading 1 in Poppins
        </h1>

        <p style={{ color: "var(--text-muted)" }}>
          This is a paragraph with some <strong>bold text</strong>.
        </p>
        <ul style={{ listStyleType: "disc", paddingLeft: "var(--padding-xl)" }}>
          <li>List item 1</li>
          <li>List item 2</li>
          <li>List item 3</li>
        </ul>
        <button
          style={{
            backgroundColor: "var(--color-silver)",
            color: "var(--color-gray)",
            border: "none",
            borderRadius: "var(--br-base)",
            padding: "var(--padding-mini) var(--padding-base)",
          }}
        >
          Click Me
        </button>
        <img
          src="https://via.placeholder.com/150"
          alt="Placeholder"
          style={{
            boxShadow: "var(--blur-glass)",
            borderRadius: "var(--br-12xl-5)",
          }}
        />
      </div>
    </>
  );
};

export default GlobalStylesTester;
