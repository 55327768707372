import {
  LogintemplateRoot,
  HeaderTitle,
  InputForm,
  InputBoxWrapper,
  InputBox,
} from "./LoginStyles";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile";
import ButtonBack from "../../components/ButtonMobile/ButtonBack";
const ResetPassword = () => {
  return (
    <LogintemplateRoot>
      <ButtonBack loading="lazy" alt="ArrowBack" />
      <HeaderTitle>Reset Password</HeaderTitle>
      <InputForm>
        <InputBoxWrapper>
          <InputBox
            required
            type="text"
            placeholder="Enter your email address"
            name="data-baseColumn"
          />
        </InputBoxWrapper>

        <ButtonMobile>Submit</ButtonMobile>
      </InputForm>
    </LogintemplateRoot>
  );
};

export default ResetPassword;
