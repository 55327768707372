import React from "react";
import styled from "styled-components";

const AbsentIconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
`;

const Icon = styled.svg`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

const AbsentIcon = () => (
  <AbsentIconWrapper>
    <Icon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="#C70039" />
      <line x1="6" y1="6" x2="18" y2="18" stroke="white" strokeWidth="2" />
      <line x1="6" y1="18" x2="18" y2="6" stroke="white" strokeWidth="2" />
    </Icon>
    <span>Absent</span>
  </AbsentIconWrapper>
);

export default AbsentIcon;