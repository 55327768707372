import React, { useEffect, useRef } from "react";
import { useButton } from "react-aria";
import { useAnimation } from "framer-motion";
import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 90px;
  padding: 0 10px;
  border-radius: 10px;
`;

export const TitleMessage = styled.h1`
  font: 400 18px Lora, sans-serif;
  text-align: center;
  margin-bottom: 0px;
  color: #fff;
  font-style: italic;
  padding: 0 10px;
`;

export const MergedButtonWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 35%;
  height: 50px;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #657f80; // Corrected background color syntax
`;

export const LogoutIcon = () => (
  <svg
    viewBox="0 0 900 1000"
    fill="#fff"
    height="2.2em"
    width="2.2em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M502 850V750h98v100c0 26.667-9.667 50-29 70s-43 30-71 30H100c-26.667 0-50-10-70-30S0 876.667 0 850V150c0-28 10-51.667 30-71s43.333-29 70-29h400c28 0 51.667 9.667 71 29s29 43 29 71v150h-98V150H100v700h402m398-326L702 720V600H252V450h450V330l198 194" />
  </svg>
);

const ButtonLogout = ({ onClick, status }) => {
  let controls = useAnimation();
  let ref = useRef();

  useEffect(() => {
    controls.start({ backgroundColor: "#657F80" }); // Ensure initial background color matches the corrected styled component
  }, [controls]);

  let { buttonProps } = useButton(
    {
      onPressStart: () => {
        controls.stop();
        controls.set({ backgroundColor: "#91b5b7" });
      },
      onPressEnd: () => {
        controls.start({
          backgroundColor: "#657F80",
          transition: { duration: 0.65 },
        });
      },
      onPress: onClick,
    },
    ref
  );

  const messages = {
    uploadedMessage: ["Logout"], //ENGLISH
    otherMessage: ["上传付款证明照片"], //CHINESE
  };

  const children = messages[status] || []; // Default to an empty array if status is unknown

  return (
    <MergedButtonWrapper animate={controls} {...buttonProps}>
      <LogoutIcon />
      {children.map((message, index) => (
        <TitleMessage key={index}>{message}</TitleMessage>
      ))}
    </MergedButtonWrapper>
  );
};

export default ButtonLogout;