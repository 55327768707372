import React from "react";
import NotificationStatus from "./NotificationStatus";
import { TitleHeader } from "../../globalStyles"; //ENGLISH TITLE
const Notification = () => {
  return (
    <>
      <TitleHeader>Notification</TitleHeader>
      <NotificationStatus />
    </>
  );
};

export default Notification;