import {
  LogintemplateRoot,
  ArrowBack,
  HeaderTitle,
  InputForm,
  InputBoxWrapper,
  InputBox,
  Message,
} from "./LoginTemplateStyles";
import ButtonMobile from "../../../components/ButtonMobile/ButtonMobile";
const LoginTemplate = () => {
  return (
    <LogintemplateRoot>
      <ArrowBack loading="lazy" alt="" src="/assets/ArrowBack.svg" />
      <HeaderTitle>Login Template File</HeaderTitle>
      <InputForm>
        <InputBoxWrapper>
          <InputBox
            required
            type="text"
            placeholder="input text1"
            name="data-baseColumn"
          />
        </InputBoxWrapper>

        <InputBoxWrapper>
          <InputBox
            required
            type="text"
            placeholder="input text2"
            name="data-baseColumn"
          />
        </InputBoxWrapper>

        <ButtonMobile>Submit</ButtonMobile>

        <Message>Forgot password</Message>

        <Message>Sign up and register</Message>
      </InputForm>
    </LogintemplateRoot>
  );
};

export default LoginTemplate;
