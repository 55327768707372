import styled from "styled-components";

export const LogintemplateRoot = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xl) 30px 100%;
  box-sizing: border-box;
  gap: 20px;
  line-height: normal;
  letter-spacing: normal;
`;

export const ArrowBack = styled.img`
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
`;

export const HeaderTitle = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-6xl) 0px var(--padding-xl);
  font-size: 30px;
  font-weight: 700;
  font-family: inherit;
  flex-shrink: 0;
`;

export const InputForm = styled.form`
  /* border: solid red 3px; */
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const InputBoxWrapper = styled.div`
  align-self: stretch;
  border-radius: var(--br-12xl-5);
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-mini) 0px var(--padding-base);
`;

export const InputBox = styled.input`
  height: 63px;
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
  color: var(--color-gray);
  text-align: left;
  display: flex;
  align-items: center;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 var(--padding-mini);
  border-radius: var(--br-12xl-5);
  &:focus {
    outline: none;
  }
`;

export const Message = styled.div`
  flex: 1;
  position: relative;
  font-weight: 500;
  font-size: var(--font-size-sm);
  cursor: pointer;
`;
