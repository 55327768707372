import { useCallback } from "react";
import styled from "styled-components";

const BackIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Glyph = styled.div`
  width: 40px;
  height: 40px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ScreenTitle = styled.i`
  align-self: stretch;
  position: relative;
  line-height: 18px;
`;
const ScreenSubtitle = styled.div`
  width: 247px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 14px;
  color: var(--text-muted);
  display: none;
`;
const TitleWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px var(--padding-29xl);
  gap: var(--gap-5xs);
`;
const Glyph1 = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Topbar = styled.div`
  align-self: stretch;
  backdrop-filter: var(--blur-glass);
  background-color: var(--color-steelblue-100);
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-5xs);
`;
const Fixed = styled.div`
  position: absolute;
  top: 44px;
  left: calc(50% - 187.5px);
  backdrop-filter: var(--blur-glass);
  background-color: rgba(51, 75, 123, 0.7);
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const CodeIcon = styled.img`
  width: 240px;
  position: relative;
  height: 240px;
  object-fit: cover;
`;
const CodeWrapper = styled.div`
  box-shadow: 0px 4px 32px rgba(255, 255, 255, 0.25);
  border-radius: var(--br-base);
  background-color: var(--text-main);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs);
`;
const Description = styled.div`
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
`;
const Qr = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base);
  gap: var(--gap-base);
`;
const TearLineChild = styled.img`
  width: 20px;
  position: relative;
  height: 40px;
`;
const TearLineItem = styled.div`
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gray-100);
  height: 12px;
`;
const EllipseIcon = styled.img`
  width: 20px;
  position: relative;
  height: 40px;
  object-fit: contain;
`;
const TearLine = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const A069IndividualPrivate = styled.i`
  flex: 1;
  position: relative;
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Venue = styled.div`
  width: 74px;
  position: relative;
  line-height: 18px;
  display: inline-block;
  flex-shrink: 0;
`;
const PrivateRink = styled.i`
  align-self: stretch;
  position: relative;
`;
const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--text-main);
`;
const Item = styled.div`
  width: 343px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Monday = styled.i`
  flex: 1;
  position: relative;
  line-height: 18px;
  color: var(--text-main);
`;
const Pm = styled.div`
  position: relative;
  line-height: 18px;
  color: var(--text-main);
`;
const BenMacaskill = styled.i`
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 18px;
  color: var(--text-main);
`;
const Details = styled.div`
  align-self: stretch;
  height: 114px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-lightsteelblue);
`;
const Info = styled.div`
  align-self: stretch;
  height: 163px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: left;
  font-family: var(--font-abeezee);
`;
const Ticket = styled.div`
  position: absolute;
  top: 108px;
  left: calc(50% - 188px);
  border-radius: 0px 0px var(--br-base) var(--br-base);
  background-color: var(--color-steelblue-100);
  width: 376px;
  height: 525px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
  font-family: var(--font-ibm-plex-sans);
`;
const QrCodeRoot = styled.div`
  border: solid green 3px;

  width: 100%;
  position: relative;
  background-color: #c4edff;
  height: 802px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--text-main);
  font-family: var(--font-abeezee);
`;

const QRCode = () => {
  const onGlyphContainerClick = useCallback(() => {
    // Please sync "notification" to the project
  }, []);

  return (
    <QrCodeRoot>
      <Fixed>
        <Topbar>
          <Glyph>
            <BackIcon alt="" src="/back.svg" />
          </Glyph>
          <TitleWrap>
            <ScreenTitle>入場門票</ScreenTitle>
            <ScreenSubtitle>Eurasia Cinema7</ScreenSubtitle>
          </TitleWrap>
          <Glyph1 onClick={onGlyphContainerClick}>
            <BackIcon alt="" src="/close.svg" />
          </Glyph1>
        </Topbar>
      </Fixed>
      <Ticket>
        <Qr>
          <CodeWrapper>
            <CodeIcon alt="" src="/code@2x.png" />
          </CodeWrapper>
          <Description>到場後請出示QR Code簽到</Description>
        </Qr>
        <TearLine>
          <TearLineChild alt="" src="/ellipse-3.svg" />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <EllipseIcon alt="" src="/ellipse-4.svg" />
        </TearLine>
        <Info>
          <Title>
            <A069IndividualPrivate>
              A069 Individual Private Class with Coach Ben
            </A069IndividualPrivate>
          </Title>
          <Details>
            <Item>
              <Venue>地點</Venue>
              <Wrap>
                <PrivateRink>私人溜冰場</PrivateRink>
              </Wrap>
            </Item>
            <Item>
              <Venue>日期</Venue>
              <Monday>逢星期一</Monday>
            </Item>
            <Item>
              <Venue>時間</Venue>
              <Pm>8:30 - 9:30pm</Pm>
            </Item>
            <Item>
              <Venue>教練</Venue>
              <BenMacaskill>Ben MacAskill</BenMacaskill>
            </Item>
          </Details>
        </Info>
      </Ticket>
    </QrCodeRoot>
  );
};

export default QRCode;
