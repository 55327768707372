import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  LogintemplateRoot,
  HeaderTitle,
  InputForm,
  InputBoxWrapper,
  InputBox,
  Message,
	TitleContainer,
	FormContainer,
	InputContainer,
	Input,
	SubmitButton,
	FlexWrapper,
	DisclaimerWrapper,
	FormFeedback,
} from "./LoginStyles";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile";
import ButtonBack from "../../components/ButtonMobile/ButtonBack";
import api from '../../Api'; // adjust the path based on where api.js is located



const SignUp = () => {

	const [formData, setFormData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		name: "",
	});
	const [formErrors, setFormErrors] = useState({});

	const navigate = useNavigate();

	// Regular expression for email validation
	const emailRegex = /\S+@\S+\.\S+/;
	// Regular expression for password validation
	const passwordRegex =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

	const validateForm = () => {
		const errors = {};

		if (!formData.email) {
			errors.email = "Email is required";
		} else if (!emailRegex.test(formData.email)) {
			errors.email = "Invalid email format";
		}

		if (!formData.password) {
			errors.password = "Password is required";
		} else if (!passwordRegex.test(formData.password)) {
			errors.password =
				"Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character";
		}

		if (!formData.confirmPassword) {
			errors.confirmPassword = "Confirming password is required";
		} else if (formData.password !== formData.confirmPassword) {
			errors.confirmPassword = "Passwords do not match";
		}

		if (!formData.name) {
			errors.name = "Name is required";
		}

		setFormErrors(errors);
		return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		// e.preventDefault();
		const isValid = validateForm();

		if (isValid) {
			try {
				const res = await api.post("/classBooking/api/auth/register", formData);
				console.log(res.data);
				navigate(`./verify/${res.data}`);
			} catch (err) {
				setFormErrors(err.response.data);
			}
		} else {
			console.log("Form is invalid! Not submitting.", formErrors);
		}
	};

  return (
    <LogintemplateRoot>
      <ButtonBack loading="lazy" alt="ArrowBack" />
      <HeaderTitle>Sign Up</HeaderTitle>
     <FormContainer onSubmit={(e) => {
  e.preventDefault();
  handleSubmit();
}}>
					<Input
						required
						type="email"
						placeholder="Email"
						name="email"
						value={formData.email}
						onChange={handleChange}
					/>
					{formErrors.email && <FormFeedback>{formErrors.email}</FormFeedback>}
					<Input
						required
						type="text"
						placeholder="Password"
						name="password"
						value={formData.password}
						onChange={handleChange}
					/>
					{formErrors.password && (
						<FormFeedback>{formErrors.password}</FormFeedback>
					)}
					<Input
						required
						type="text"
						placeholder="Confirm password"
						name="confirmPassword"
						value={formData.confirmPassword}
						onChange={handleChange}
					/>
					{formErrors.confirmPassword && (
						<FormFeedback>{formErrors.confirmPassword}</FormFeedback>
					)}
					<Input
						required
						type="text"
						placeholder="Your name"
						name="name"
						value={formData.name}
						onChange={handleChange}
					/>
					{formErrors.name && <FormFeedback>{formErrors.name}</FormFeedback>}
					<ButtonMobile onClick={handleSubmit}>Register</ButtonMobile>
					{/* {error && <p>{error}</p>} */}
				</FormContainer>
        	<FlexWrapper>

        	<Link to="/login"><Message>Forgot Password</Message></Link>

      <Link to="/login"><Message>Sign Up and Register</Message></Link>
			
				
				</FlexWrapper>
				<DisclaimerWrapper>
					By continuing, you agree to Dynamic's Privacy Policy and Terms of
					Service
				</DisclaimerWrapper>
				<DisclaimerWrapper>
					© 2002 - 2023 DYNAMIC MANAGEMENT GROUP LIMITED. <br /> ALL RIGHTS
					RESERVED.
				</DisclaimerWrapper>
    </LogintemplateRoot>
  );
};

export default SignUp;
