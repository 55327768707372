import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

export function AlertIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="5em"
      width="5em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 15h2v2h-2v-2m0-8h2v6h-2V7m1-4a9 9 0 00-9 9 9 9 0 009 9 9 9 0 009-9 9 9 0 00-9-9m0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7m8.5 1.5c2.16-2.19 3.5-5.19 3.5-8.5 0-3.31-1.34-6.31-3.5-8.5l-1.08 1.08C21.32 6.5 22.5 9.11 22.5 12c0 2.9-1.18 5.5-3.08 7.42l1.08 1.08M4.58 19.42C2.68 17.5 1.5 14.9 1.5 12c0-2.89 1.18-5.5 3.08-7.42L3.5 3.5C1.34 5.69 0 8.69 0 12c0 3.31 1.34 6.31 3.5 8.5l1.08-1.08z" />
    </svg>
  );
}

// Styled component for the container div
const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15em;
  height: 5em;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 1em;
  border: 1px solid white;
  background-color: red;
  color: #fff;
`;

// Styled component for the text span
const Text = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-left: 0em;
  padding-right: 1em;
`;

// Define animation variants for the icon
const iconVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: [1, 0.8, 1, 0.8, 1], // Keyframes for scale animation
    transition: {
      duration: 0.5,
      ease: "easeInOut", // Easing function
      repeat: Infinity, // Repeat indefinitely
      repeatType: "reverse", // Alternate between keyframes
      repeatDelay: 1, // Delay between repeats
    },
  },
};

// Define animation variants for the container
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.6,
    },
  }, // Added missing closing brace for the visible object
};
const Alerts = () => {
  return (
    <Container variants={containerVariants} initial="hidden" animate="visible">
      <motion.div
        variants={iconVariants}
        initial="hidden"
        animate="visible"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }} // Center the icon
      >
        <AlertIcon />
      </motion.div>
      <Text>Alert!</Text>
    </Container>
  );
};

export default Alerts;
