import {
  LogintemplateRoot,
  HeaderTitleC,
  InputForm,
  InputBoxWrapper,
  InputBox,
} from "./LoginStyles";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile";
import ButtonBack from "../../components/ButtonMobile/ButtonBack";

const ResetPasswordC = () => {
  return (
    <LogintemplateRoot>
      <ButtonBack loading="lazy" alt="ArrowBack" />
      <HeaderTitleC>重設密碼</HeaderTitleC>
      <InputForm>
        <InputBoxWrapper>
          <InputBox
            required
            type="text"
            placeholder="請輸入您的電郵地址"
            name="data-baseColumn"
          />
        </InputBoxWrapper>

        <ButtonMobile>提交</ButtonMobile>
      </InputForm>
    </LogintemplateRoot>
  );
};

export default ResetPasswordC;
