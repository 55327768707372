import React from "react";
import ClassesC from "../classes/ClassesC";

import { TitleHeaderC } from "../../globalStyles"; //CHINESE TITLE
const HomePageC = () => {
  return (
    <div>
      <TitleHeaderC>課程</TitleHeaderC>
      <ClassesC />
    </div>
  );
};

export default HomePageC;

