import React, { useEffect, useRef } from "react";
import { useButton } from "react-aria";
import { useAnimation } from "framer-motion";
import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 90px;
  padding: 0 10px;
  border-radius: 10px;
`;

export const TitleMessage = styled.h1`
  font: 400 18px Lora, sans-serif;
  text-align: center;
  margin-bottom: 0px;
  color: #fff;
  font-style: italic;
  padding: 0 10px;
`;

export const MergedButtonWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 35%;
  height: 50px;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #657f80; // Corrected background color syntax
`;

export const EditIcon = () => (
  <svg
    viewBox="0 0 1024 1024"
    fill="#fff"
    height="2.2em"
    width="2.2em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32zm-622.3-84c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9z" />
  </svg>
);

const ButtonUpdate = ({ onClick, status }) => {
  let controls = useAnimation();
  let ref = useRef();

  useEffect(() => {
    controls.start({ backgroundColor: "#657F80" }); // Ensure initial background color matches the corrected styled component
  }, [controls]);

  let { buttonProps } = useButton(
    {
      onPressStart: () => {
        controls.stop();
        controls.set({ backgroundColor: "#91b5b7" });
      },
      onPressEnd: () => {
        controls.start({
          backgroundColor: "#657F80",
          transition: { duration: 0.65 },
        });
      },
      onPress: onClick,
    },
    ref
  );

  const messages = {
    uploadedMessage: ["Update"], //ENGLISH
    otherMessage: ["上传付款证明照片"], //CHINESE
  };

  const children = messages[status] || []; // Default to an empty array if status is unknown

  return (
    <MergedButtonWrapper animate={controls} {...buttonProps}>
      <EditIcon />
      {children.map((message, index) => (
        <TitleMessage key={index}>{message}</TitleMessage>
      ))}
    </MergedButtonWrapper>
  );
};

export default ButtonUpdate;