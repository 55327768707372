import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export function CheckCircle(props) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="4em"
      width="4em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-3.97-3.03a.75.75 0 00-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 00-1.06 1.06L6.97 11.03a.75.75 0 001.079-.02l3.992-4.99a.75.75 0 00-.01-1.05z" />
    </svg>
  );
}

export function QrIcon(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="black"
      height="4em"
      width="4em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M336 336h80v80h-80zM272 272h64v64h-64zM416 416h64v64h-64zM432 272h48v48h-48zM272 432h48v48h-48zM336 96h80v80h-80z" />
      <path d="M480 240H272V32h208zm-164-44h120V76H316zM96 96h80v80H96z" />
      <path d="M240 240H32V32h208zM76 196h120V76H76zM96 336h80v80H96z" />
      <path d="M240 480H32V272h208zM76 436h120V316H76z" />
    </svg>
  );
}

// Styled component for the container div

const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15em;
  height: 5em;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 1em;
  border: 1px solid #fff;
  background-color: green;
  color: white;
`;

// Styled component for the text span
const Text = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: bold;
`;

const IconDiv = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid black;
  align-items: center;
  justify-content: space-around;
  width: 5em;
  height: 5em;
  padding: 3px;
  box-sizing: border-box;
  color: white;
  border-radius: 5px;
  margin-left: 1em;
  cursor: pointer;
`;
const Approved = (orderId) => {

  return (
    <>
      <WrapperDiv>
        <Container>
          <CheckCircle />
          <Text>Approved</Text>
        </Container>
        <Link to={`/booking/${orderId.orderId}`}><IconDiv>
          <QrIcon />
        </IconDiv>
        </Link>
      </WrapperDiv>
    </>
  );
};

export default Approved;
