import React from "react";
import styled from "styled-components";

const AttendedIconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
`;

const Icon = styled.svg`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

const AttendedIcon = () => (
  <AttendedIconWrapper>
    <Icon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="green" />
      <polyline
        points="6 12 10 16 18 8"
        stroke="white"
        strokeWidth="2"
        fill="none"
      />
    </Icon>
    <span>Attended</span>
  </AttendedIconWrapper>
);

export default AttendedIcon;