import React from "react";
import Classes from "../classes/Classes";
import { TitleHeader } from "../../globalStyles"; //ENGLISH TITLE

const HomePage = () => {
  return (
    <>
      <TitleHeader>Select Class</TitleHeader>
      <Classes />
    </>
  );
};

export default HomePage;
