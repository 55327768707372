import {
  LogintemplateRoot,
  HeaderTitle,
  InputForm,
  InputBoxWrapper,
  InputBox,
} from "./LoginStyles";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile";
import ButtonBack from "../../components/ButtonMobile/ButtonBack";

const ConfirmNewPasswordC = () => {
  return (
    <LogintemplateRoot>
      <ButtonBack loading="lazy" alt="ArrowBack" />
      <HeaderTitle>確認新密碼</HeaderTitle>
      <InputForm>
        <InputBoxWrapper>
          <InputBox
            required
            type="text"
            placeholder="請輸入新密碼"
            name="data-baseColumn"
          />
        </InputBoxWrapper>

        <InputBoxWrapper>
          <InputBox
            required
            type="text"
            placeholder="請重新輸入新密碼"
            name="data-baseColumn"
          />
        </InputBoxWrapper>

        <ButtonMobile>確認</ButtonMobile>
      </InputForm>
    </LogintemplateRoot>
  );
};

export default ConfirmNewPasswordC;
