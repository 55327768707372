//ClassDetails.js
import React, { useEffect, useState, useRef, useContext  } from "react";
import { useNavigate, useLocation} from 'react-router-dom';
import styled from "styled-components";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile";
import ButtonBack from "../../components/ButtonMobile/ButtonBack";
import Calender from "../../components/Calender/Calender";
import ButtonUploadPayment from "../../components/ButtonMobile/ButtonUploadPayment";
import { TitleHeader } from "../../globalStyles";
import Cookies from 'js-cookie';
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import { HistoryContext } from '../../context/historyContext';
import MessageModal from "../../components/Modal/Modal.jsx";

import {AddUserMemberForm} from "../../components/Form/Form";
import {ConsentCheckbox} from "../../components/Form/ConsentCheckbox";
import api from '../../Api'; // adjust the path based on where api.js is located



import {  LoadingOverlay,
  LoadingSpinner,
} from "../../globalStyles.js";



import {
  FormContainer,
  Input,
  FormFeedback
} from "../../pages/loginpages/LoginStyles";



export const ClassCard = ({programInfo, classInfo, orderDetails}) => {
  const navigate = useNavigate()

    const { currentUser, loading } = useContext(AuthContext);

 
        const [student, setStudent] = useState(null)
          const [contact, setContact] = useState(null);
const [errors, setErrors] = useState({});

const [isFormInitialized, setIsFormInitialized] = useState(false);

useEffect(() => {
  const initializeForm = () => {
    // Initialize student form fields based on setting
    if (programInfo?.requireConsent) {
   setStudent( { firstname: "",
    lastname: "",
    dob: "",
    gender: "",
  photoConsent: "",
disclaimerConsent:""})
    } else {
   setStudent( { firstname: "",
    lastname: "",
    dob: "",
    gender: ""})
    }

    setContact({ mobile: "", email: "" });

       setIsFormInitialized(true);
  };


  initializeForm();


}, [programInfo]); // Run this effect when courseId changes

	// Regular expression for email validation
	const emailRegex = /\S+@\S+\.\S+/;
  const mobileRegex =/^\d{8}$/;



const validateFormData = () => {

  let newErrors = {};
  // Validate student data
  for (let key in student) {
    if (student[key] === "" || student[key] == null || student[key] == undefined || student[key] == false) {
      newErrors[key] = "此欄位為必填";
    } else {

if (key === "dob"){
const dob = new Date(student.dob);
      const today = new Date();
      const minDate = new Date("1920-01-01");
        const year = dob.getFullYear();

if (isNaN(year)) {
   newErrors.dob = "無效日期";
  } else if (year < 1920 || dob > new Date()) {
    newErrors.dob = "出生日期必須介於1920年和今天之間。";
  }
      }
    }
  }

  // Validate contact data
  for (let key in contact) {
    if (contact[key] === "" || contact[key] == null || contact[key] == undefined) {
      newErrors[key] = "此欄位為必填";
    } else {
      if (key === "email" && !emailRegex.test(contact[key])) {
 newErrors["email"] = "無效的電子郵件格式";
  }

if(key === "mobile" && !mobileRegex.test(contact[key])){
newErrors["mobile"] = "手機號碼必須為8位數且僅能包含數字。";
}

}
  }
  setErrors(newErrors);
  // If there are no errors, return true
  return Object.keys(newErrors).length === 0;
};




const [userMember, setUserMember] = useState([]);
    const [tab, setTab] = useState(null);
      const [image, setImage] = useState(null);
      	const [modalOpen, setModalOpen] = useState(false);
        const [autoClose, setAutoClose] = useState(true);
	const [modalTemplate, setModalTemplate] = useState("");
    const [loadingCircle, setLoadingCircle] = useState(false);
  const [files, setFiles] = useState(null); //Handle Upload Photos
    const [uploadDir, setUploadDir] = useState(null);
        const [submitImage, setSubmitImage] = useState(null);


  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
          setSubmitImage(file);
    const reader = new FileReader();
    console.log(file)

    reader.onloadend = () => {
      // Here you can handle the preview of the image
      console.log(reader.result);
            setImage(reader.result);

    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };


useEffect(() => {
       let activeCourseFromCookie = Cookies.get('activeCourse');
  if (!loading && programInfo?.requireLogin && !currentUser) {
    navigate('/login'); // replace '/login' with your actual login route
  } else if (!activeCourseFromCookie){
 navigate(history); // r
  }
}, [programInfo, currentUser]);



  const host = window.location.hostname;
const subdomain = host.split('.')[0];
const shopName = subdomain;
const { history, setHistory } = useContext(HistoryContext);


  useEffect(() => {

 const savedHistory = localStorage.getItem('history');
  if (savedHistory) {
    setHistory(JSON.parse(savedHistory));
  }



		const fetchData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/user/getUserMembers`,{
            withCredentials: true,
        }
				);
				setUserMember(res.data);
          setTab(res.data[0].id)
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, []);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setContact((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


const openModalConsent = (template, e)=> {
   e.preventDefault(); // Prevent the checkbox from being checked
		setModalOpen(true);
		setModalTemplate(template);
    setAutoClose(false)
  }


const SubmitOrder = async () => {

 const isValid = validateFormData();

    if (isValid) {
    setLoadingCircle(true);

try {
				const res = await api.post(
					`/classBooking/api/orders`,
					{orderDetails,contact,student, "status":Number(!programInfo.requireConfirmBooking)},{   withCredentials: true,}
				);


        if (res.status === 200) {

  orderDetails.orderId = res.data.orderId;
  orderDetails.token = res.data.token;

if (programInfo.requirePayment) {
            // Upload the imag

          const formData = new FormData();


  formData.append('photos', submitImage); // 'image' is the name of the field
//   // Submit the form data and the image
  const response = await api.post(`/classBooking/api/orders/uploadReceipt?orderId=${res.data.orderId}`, formData, {   withCredentials: true,});

 
   

    if (response.status === 200) {

const emailData = {
  recipientEmail: contact.email, // replace with actual recipient email
  orderDetails: orderDetails, // use the data you submitted before
  student: {
    firstname: student.firstname, 
    lastname: student.lastname, 
    dob: student.dob, 
    gender: student.gender
  }
};
  
  // Send confirmation email out
  const sendOutEmail = await api.post(`/classBooking/api/orders/emailConfirmationEmail`, emailData, {   withCredentials: true,});


  // Send confirmation email out
  const sendOutOrderEmail = await api.post(`/classBooking/api/orders/emailOrderEmail`, emailData, {   withCredentials: true,});

    } 

  }  else {

const emailData = {
  recipientEmail: contact.email, // replace with actual recipient email
  orderDetails: orderDetails, // use the data you submitted before
  student: {
    firstname: student.firstname, 
    lastname: student.lastname, 
    dob: student.dob, 
    gender: student.gender
  },
  language:"ch"
};

if(programInfo.requireConfirmBooking){
  // Send confirmation email out
  const sendOutEmail = await api.post(`/classBooking/api/orders/emailConfirmationEmail`, emailData, {   withCredentials: true,});

  } else {

 const sendTicket = await api.post(`/classBooking/api/orders/sendTicket`, emailData , {   withCredentials: true,});

  }
  // Send confirmation email out
  const emailOrderEmailNotice = await api.post(`/classBooking/api/orders/emailOrderEmailNotice`, emailData, {   withCredentials: true,});


  }


//     // Add student
//   const addStudent = await axios.post(`http://${shopName}.booking.dynevents.local:8089/classBooking/api/student/add`, emailData, {   withCredentials: true,});




        // If the order is submitted successfully, clear the activeCourse cookie
        Cookies.remove('activeCourse');

        setLoadingCircle(false);
				setModalOpen(true);
				setModalTemplate("success");
				setTimeout(() => {
					navigate(`/ch/order/${res.data.token}`);
				}, 3000);

      // }
    } 



			} catch (err) {
	
  setLoadingCircle(false);
				setModalOpen(true);
				setModalTemplate("fail");
				setTimeout(() => {
					  navigate(history);
				}, 3000);
			}
    }
}

  return (
    <>
      <CardContent>
        <ButtonWrapper>
        <ButtonBack onClick={() => navigate(-1)}></ButtonBack>
        </ButtonWrapper>
         <TitleHeader>確認預約</TitleHeader>

        <ClassInfoWrapper>
          <Label> {classInfo?.code}</Label> 
          <ClassTitle>
        {programInfo?.name_chi}
          </ClassTitle>

     <ClassDetailsStyled>
         <DetailValue> {classInfo?.
option1value_chi || classInfo?.
option1value
}</DetailValue>
 <DetailValue> {classInfo?.
option2value_chi || classInfo?.
option2value
}</DetailValue>
<DetailValue> 總共 {classInfo?.
noofclasses
} 堂</DetailValue>

  </ClassDetailsStyled>
         
      <OptionListPrice>
  {classInfo?.fee === "0" ? '免費' : `HK$ ${classInfo?.fee}`}
</OptionListPrice>

        </ClassInfoWrapper>

<ClassInfoWrapper>
        <Subheader>參加者資料</Subheader>

     {programInfo?.requireLogin && currentUser ? (
    <OptionListContainer>
      {userMember?.map((info, index) => {
        const dateOfBirth = new Date(info.dateofbirth);
        const formattedDateOfBirth = dateOfBirth.toLocaleDateString();

        return (
        <OptionList $active={tab === info.id} onClick={() => { setStudent({ 
  firstname: info.first_name,
  lastname: info.last_name,
  dob: formattedDateOfBirth,
  gender: info.gender
});

setTab(info.id);

}}>
            <OptionListTopContainer>
              <Label>ST{info.id}</Label>
              <OptionListTitle>{info.first_name} {info.last_name}</OptionListTitle>	
            </OptionListTopContainer>
            <OptionListMiddleContainer>Date of birth: </OptionListMiddleContainer>
            <OptionListMiddleContainer>{formattedDateOfBirth} </OptionListMiddleContainer>
            <OptionListMiddleContainer>{info.gender} </OptionListMiddleContainer>
          </OptionList>
        )
      })}
    </OptionListContainer>


      ) : ( 

   <AddUserMemberForm formData={student} setFormData={setStudent} withSubmitBtn={false} errors={errors} language={"ch"}/>)}

        </ClassInfoWrapper>

   <ClassInfoWrapper>
        <Subheader>聯絡資料</Subheader>

          <FormContainer>
                       <Note>請輸入有效的電話號碼作WhatsApp聯絡</Note>
    <Input
              required
              type="text"
              placeholder="電話號碼"
              name="mobile"
              onChange={handleChange}
            />
               {errors.mobile && (
        <FormFeedback>{errors.mobile}</FormFeedback>
      )}

     <Note>請輸入有效的電郵，以收到電郵確認</Note>
              <Input
              required
              type="text"
              placeholder="電郵"
              name="email"
              onChange={handleChange}
            />

      {errors.email && (
        <FormFeedback>{errors.email}</FormFeedback>
      )}
         <Note>請截圖確認頁面以供參考，另請注意，你將會在預訂日前一天收到簡訊提醒。</Note>

{programInfo?.requireConsent ? (
<><ConsentCheckbox formData={student} setFormData={setStudent} language={"ch"} template={"photoConsent"}/>
 {errors.photoConsent&& (
        <FormFeedback>{errors.photoConsent}</FormFeedback>
      )}
<ConsentCheckbox formData={student} setFormData={setStudent} language={"ch"} template={"disclaimerConsent"} content={programInfo?.disclaimer_ch} onClick={(e)=>openModalConsent("disclaimerConsent", e)}  requireModalConsent={true}/>
 {errors.disclaimerConsent&& (
        <FormFeedback>{errors.disclaimerConsent}</FormFeedback>
      )}
 
</>) : (null)  }

          </FormContainer>


      </ClassInfoWrapper>





{programInfo?.requirePayment ? (
      <ClassInfoWrapper>
        <Subheader>付款方式</Subheader>
        <Note>可選擇轉數快 / PayMe / 銀行轉帳進行付款</Note>
        <Note>請確保以下資料清晰顯示</Note>
        <Note>*您的銀行戶口號碼</Note>
        <Note>*交易日期</Note>
        <Note>*付款金額</Note>
        <br />
           


 <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="file"
      />

        <ButtonUploadPayment
          status="uploadedMessage"
          onClick={handleButtonClick}
       >
            {/* <input type="file" accept="image/*" onChange={handleImageUpload} /> */}
            </ButtonUploadPayment>
            <ImageContainer>
              {image && (
        <img src={image} alt="Preview" style={{ height: '100px', width: '100px' }} />
      )}
              </ImageContainer>
               </ClassInfoWrapper>
) : (null)  } 
           

        <FooterWrapper>
           <ButtonMobile onClick={SubmitOrder}>確認預約</ButtonMobile>
        </FooterWrapper>
      </CardContent>

      {modalOpen && (
				<MessageModal
					isOpen={modalOpen}
					template={modalTemplate}
          language={"ch"}
					autoClose={autoClose}
          setModalOpen={setModalOpen}
          formData={student}
          setFormData={setStudent}
          content={programInfo?.disclaimer_ch}
				/>
			)}
        {/*************************************** 
            // LOADING CIRCLE //
        ***************************************/}
            {loadingCircle && (
              <LoadingOverlay>
                <LoadingSpinner />
              </LoadingOverlay>
            )}
    </>
  );
};

export const ClassConfirmPayment = () => {
  

    const navigate = useNavigate()
    const location = useLocation();

const { history, setHistory } = useContext(HistoryContext);
  const [classInfo, setClassInfo] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [programInfo, setProgramInfo] = useState(null);

useEffect(() => {
    
    let activeCourse;
   const activeCourseCookie = Cookies.get('activeCourse');


if (activeCourseCookie) {
  activeCourse = JSON.parse(activeCourseCookie);
  setClassInfo(activeCourse?.course);

const program_code = activeCourse?.program;

	const fetchProgramData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/class/${program_code}`
				);

		
			setProgramInfo(res.data);
          

			} catch (err) {
				console.log(err);
			}
		};

		fetchProgramData();

  } else {
  navigate(history);
    }
},[]);


  useEffect(() => {
  if (classInfo && programInfo) {
const { code: courseCode, fee, option1value, option2value, id, option1value_chi, option2value_chi } = classInfo;
const order_details = {
  id,
  courseCode,
  fee,
  option1value,
  option2value,
  option1value_chi,
  option2value_chi,
  programName: programInfo?.name,
  shopId: programInfo?.shop_id,
  venue: programInfo?.venue,
  programNameChi: programInfo?.name_chi,
  venue_chi: programInfo?.venue_chi
};

setOrderDetails(order_details);
} 
  },[programInfo, classInfo]);
 
  return (
    <>
        <ClassCard classInfo={classInfo} orderDetails={orderDetails}  programInfo={programInfo}/>
    </>
  );
};
export default ClassConfirmPayment;

//STYLED-COMPONENTS:
export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 10;
`;

export const NoteTitle = styled.div`
  font: 800 32px Lora, sans-serif;
  text-align: center; // Center text
  margin-top: 20px;
  margin-bottom: 0px; // Adjust spacing after title
  color: #0c729e;
  font-style: italic;
`;

export const Note = styled.div`
  font: 800 15px Lora, sans-serif;
  text-align: center; // Center text
  margin-top: 0px;
  margin-bottom: 0px; // Adjust spacing after title
  color: #0c729e;
  font-style: italic;
`;

export const ClassInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  width:100%;
  padding:10px 10px;
  // border-radius: 0 0 20px 20px;
  border-bottom:15px solid lightgrey;
`;

export const ClassTitle = styled.h1`
  // border: solid black 1px;
  // background-color: #fff;
  max-width: 400px;
  height: auto;
  font: 700 18px Poppins, sans-serif;
  text-align: left; // Center text
  margin: 10px 0;
  // padding: 0 20px;
`;

export const ClassDetailsStyled = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 3fr; // Defines two columns: 1st column is 1/4th, 2nd column is 3/4ths of the total width
  // gap: 10px; // Gap between grid items
  width: 100%; //Makes the grid 80% of the width of the parent container
  padding-bottom: 10px;
`;

export const DetailItem = styled.div`
  display: contents; // Makes the container behave like a direct child of the grid container
`;

// export const DetailLabel = styled.span`
//   color: var(--Text-Muted, gray);
//   font-family: Poppins, sans-serif;
//   font-size: 14px;
//   font-weight: 600;
//   color: #eee;
// `;

export const DetailValue = styled.div`
  font-family: Lora, sans-serif;
  width:100%;
  color: black;
  font-weight: 500;
  font-size: 16px;
  // text-decoration: underline;
  font-style: italic;
`;

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px 12px 0 0;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 11px;
  gap: 20px;
  z-index: 1000;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // Very thin drop shadow
  @media (min-width: 500px) {
    width: 500px; /* Apply max-width constraint */
    left: calc((100vw - 500px) / 2);
    right: calc((100vw - 500px) / 2);
  }
`;

export const InputForm = styled.form`
  /* border: solid red 3px; */
  padding: 0px 20px 0px 20px;
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

export const InputBoxWrapper = styled.div`
  align-self: stretch;
  border-radius: var(--br-12xl-5);
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-mini) 0px var(--padding-base);
`;

export const InputBox = styled.input`
  height: 63px;
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
  color: var(--color-gray);
  text-align: left;
  display: flex;
  align-items: flex-start;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 var(--padding-mini);
  border-radius: var(--br-12xl-5);
  &:focus {
    outline: none;
  }
`;


export const Label = styled.div`
  font: 15px Lora, sans-serif;
  text-align: center; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  background-color:lightgrey;
  color:black;
  border-radius:2px;
  padding:2px 5px;

`;

export const OptionListPrice = styled.div`
	// width: 100%;
	// margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:800;
`;


export const Subheader = styled.div`
  font-weight: 700;
  font-size: 20px;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  margin-bottom:20px;
  // display:flex;
  // align-items:center;
  // justify-content:flex-start;
  border-left:10px solid rgb(0,0,139);
  width:100%;
  color:black;
  padding-left:20px;
  

`;


export const OptionListContainer = styled.div`
  position: relative;
	width: 100%;
	max-width: 978px;
	margin: 0px auto;
	padding: 5px 0;
	// margin-bottom: 15px;
	// border:10px solid blue;
	height: 220px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
      gap: 10px;
	background-color: none;
  overflow: auto !important; // enable horizontal scrolling
  // margin-bottom:20px;
  
  

	position: sticky; // Add this line
	// top: 0; // And this line
	z-index: 999;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


	// @media (max-width: 576px) {
	// 	margin: 0;
	// 	padding: 0px 0px 30px 0px;
	// }
`;

export const OptionList = styled.button`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
	// width: 300px;
	border: 2px solid rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 200px;
  border-radius: 50%;
	// height: 100%;
	background-color:#ffffff;
	color: rgba(0, 0, 0, 1);
	font-size: clamp(18px, 2vw, 2rem);
	min-width: 200px;
	padding: 10px 10px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	//Acts like an 'active' tab
	border: ${(props) =>
		props.$active
			? "3px solid rgba(255, 133, 89, 1)"
			: "2px solid rgba(0, 0, 0, 0.1)"};

	background-color:${(props) =>
		props.$active
			? "rgba(255, 133, 89, 0.3)"
			: "#ffffff"};

`;



export const DynamicallyChangeContent = styled.div`
  text-align: left; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  // background-color:rgba(0,0,0,0.5);
  color:black;
  // padding:2px 5px;
  width:100%;
    height:100%;
    position:absolute;
    

   ul {
    list-style-type: disc; // Use disc for bullet points
    padding-left: 20px; // Add some padding to indent the list
      font-size:18px;
    
  }

`;

export const DynamicallyChangeContentContainer =styled.div`
display: flex;
overflow: hidden; /* Add this to hide the components that are off-screen */
width: 100%;
background-color:rgba(	255, 242, 237,1);
  border-radius:15px;
  	padding: 10px 10px;
    // border:1px solid red;
    // min-height:100px;
    height:200px;
      position: relative;
  margin-top:20px;


`

export const OptionListTopContainer = styled.div`
	// flex: 2;
	display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
	// border: 1px solid blue;
`;

export const OptionListMiddleContainer = styled.div`
color:grey;
font-size:15px;
text-align:left;
	// border: 1px solid blue;
`;

export const OptionListIconContainer = styled.div`
	flex: 1;
	width: 100%;
	// border: 1px solid red;

	svg {
		width: 30%;
		fill: #ffffff;
	}
`;

export const OptionListTitle = styled.div`
	width: 100%;
	margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:600;
`;


export const ImageContainer = styled.div`
	width: 50%;
	margin: 10px auto;

img{

width:100%;

}
`;
