import React, { useState } from 'react';

export const HistoryContext = React.createContext();

export function HistoryProvider({ children }) {
  const [history, setHistory] = useState([]);

  return (
    <HistoryContext.Provider value={{ history, setHistory }}>
      {children}
    </HistoryContext.Provider>
  );
}