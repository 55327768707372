// src/layouts/MainLayout.js
import React from "react";
import NavbarBottom from "../components/NavbarBottom/NavbarBottom";

const MainLayout = ({ children }) => {
  return (
    <>
      {children}
      <NavbarBottom />
    </>
  );
};

export default MainLayout;
