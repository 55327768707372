import React from "react";
import { TitleHeaderC } from "../../globalStyles"; //CHINESE TITLE
import BookingApprovedC from "./BookingApprovedC";

const BookingsC = () => {
  return (
    <>
      <TitleHeaderC>課程</TitleHeaderC> <BookingApprovedC />
    </>
  );
};

export default BookingsC;
