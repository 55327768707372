//ClassDetails.js
import React, { useEffect, useState, useRef, useContext  } from "react";
import { useNavigate, useParams} from 'react-router-dom';
import styled from "styled-components";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile";
import ButtonBack from "../../components/ButtonMobile/ButtonBack";

import { TitleHeader } from "../../globalStyles";
import axios from "axios";
import Approved from "../../components/StatusIcons/Approved_No_qrCode";
import Pending from "../../components/StatusIcons/Pending";
import Alert from "../../components/StatusIcons/Alert";

import QrCodeTicket from "../../components/QrCode/QrCodeTicket";

import api from '../../Api'; // adjust the path based on where api.js is located




import {  LoadingOverlay,
  LoadingSpinner,
} from "../../globalStyles.js";



import {
  FormContainer,
  Input,
  FormFeedback,
  SubmitButton,
} from "../../pages/loginpages/LoginStyles";



function getStatusComponent(statusCode,orderId) {
  switch (statusCode) {
    case 1:
      return <Approved orderId={orderId} />;
    case 0:
      return <Pending orderId={orderId}/> ;
    case "alert":
      return <Alert orderId={orderId}/> ;
    default:
      return null; // Consider handling unknown statuses explicitly
  }
}

export const StatusIconWrapper = styled.div`
  /* border: 5px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;


export const ClassCard = ({orderInfo}) => {
  const navigate = useNavigate()

  	const { token } = useParams();


    // const { currentUser, loading } = useContext(AuthContext);
     const [loadingCircle, setLoadingCircle] = useState(false);
       const [attendance, setAttendance] = useState("");
         const [submissionStatus, setSubmissionStatus] = useState(false);


// const orderDetails = orderInfo?.order_details ? JSON.parse(orderInfo?.order_details) : null;
// const participantDetails = orderInfo?.participant_info ? JSON.parse(orderInfo?.participant_info) : null;
// const contact = orderInfo?.contact;
// const email = orderInfo?.email;


// const StatusComponent = getStatusComponent(orderInfo[0]?.status, orderInfo[0]?.id);

	// const orderStatus = orderInfo?.status;
  // const replyStatus = classAttendance?.reply_status;


useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/class/getAttendance/${token}`
				);

const attendanceData = res.data;

				setAttendance(attendanceData);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();

	}, [token,submissionStatus]);




const handleSubmit = async (e, attended) => {

    e.preventDefault();
    // console.log

      // Set the status based on whether the user attended or not
let respondStatus = attended ? '1' : '9';

try {
				const res = await api.put(
					`/classBooking/api/class/respondAttendance/${token}`,
          {respondStatus}
				);

              setSubmissionStatus(!submissionStatus); // Toggle submission status to trigger useEffect

			} catch (err) {
				console.log(err);
			}



}

  return (
    <>
      <CardContent>
        {/* <ButtonWrapper>
        <ButtonBack onClick={() => navigate(-1)}></ButtonBack>
        </ButtonWrapper> */}
        <TitleHeader>Confirm your attendance</TitleHeader>

              {/* Wrap the StatusComponent in the StatusIconWrapper for consistent styling */}
          {/* {StatusComponent && (
            <StatusIconWrapper>
                        {StatusComponent}
            </StatusIconWrapper>
          )} */}


{attendance?.reply_status === 0 ? (<><Note>Please reply to confirm your attendence</Note>
          <ButtonWrapper>
	<SubmitButton
						action="approve"   onClick={(e) => handleSubmit(e, true)}

					>Confirm attendance</SubmitButton>
	<SubmitButton
						action="cancel"   onClick={(e) => handleSubmit(e, false)}
					>Sorry, I won't be able to attend</SubmitButton>

</ButtonWrapper></>) : (
  attendance?.status === 9 ? (
    <StatusContainer status={9}>Booking cancelled</StatusContainer>
  ) : (
       <StatusContainer status={1}>Attendance confirmed</StatusContainer>
  )

)}

           {orderInfo && <QrCodeTicket orderInfo={orderInfo} language={"en"} />}
           

        {/* <ClassInfoWrapper>
          <Label> {orderDetails?.courseCode}</Label> 
          <ClassTitle>
        {orderDetails?.programName}
          </ClassTitle>

     <ClassDetailsStyled>
         <DetailValue> {orderDetails?.
option1value
}</DetailValue>
 <DetailValue> {orderDetails?.
option2value
}</DetailValue>
  </ClassDetailsStyled>
         
      <OptionListPrice>
  {orderDetails?.fee === "0" ? 'Free' : `HK$ ${orderDetails?.fee}`}
</OptionListPrice>

        </ClassInfoWrapper>

<ClassInfoWrapper>
        <Subheader>Participant Info</Subheader>

          <ClassTitle>
        {participantDetails?.firstname} {participantDetails?.lastname}
          </ClassTitle>

     <ClassDetailsStyled>
         <DetailValue>     {participantDetails?.dob}</DetailValue>
 <DetailValue> {participantDetails?.
gender
}</DetailValue>
  </ClassDetailsStyled>
         
        </ClassInfoWrapper>

   <ClassInfoWrapper>
        <Subheader>Contact Info</Subheader>

          <ClassTitle>
        {contact}
          </ClassTitle>

     <ClassDetailsStyled>
         <DetailValue> {email}</DetailValue>
  </ClassDetailsStyled>
         
        </ClassInfoWrapper> */}

{/* {orderInfo?.image ? (
      <ClassInfoWrapper>
        <Subheader>Payment Method</Subheader>
           <ImageContainer>
        <img src={orderInfo?.image} alt="Preview" style={{ height: '100px', width: '100px' }} />
              </ImageContainer>  

               </ClassInfoWrapper>

) : null} */}
      </CardContent>

        {/*************************************** 
            // LOADING CIRCLE //
        ***************************************/}
            {loadingCircle && (
              <LoadingOverlay>
                <LoadingSpinner />
              </LoadingOverlay>
            )}
    </>
  );
};

export const ConfirmAttendance = () => {

const host = window.location.hostname;
const subdomain = host.split('.')[0];
const shopName = subdomain;

  const [order, setOrder] = useState(null);
  	const {token} = useParams();

useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/orders/${token}`
				);

const orderData = res.data;
orderData.token = token;

				setOrder(orderData);

                console.log(res.data)
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();

	}, [token]);
 

  return (
    <>
          {order && <ClassCard orderInfo={order} />}
    </>
  );
};
export default ConfirmAttendance;

//STYLED-COMPONENTS:
export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
`;

export const ButtonWrapper = styled.div`
width:50%;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
gap:10px;
margin: 10px auto;

`;

export const NoteTitle = styled.div`
  font: 800 32px Lora, sans-serif;
  text-align: center; // Center text
  margin-top: 20px;
  margin-bottom: 0px; // Adjust spacing after title
  color: #0c729e;
  font-style: italic;
`;

export const Note = styled.div`
  font: 800 15px Lora, sans-serif;
  text-align: center; // Center text
  margin-top: 0px;
  margin-bottom: 0px; // Adjust spacing after title
  color: #0c729e;
  font-style: italic;
`;

export const ClassInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  width:100%;
  padding:10px 10px;
  // border-radius: 0 0 20px 20px;
  border-bottom:15px solid lightgrey;
`;

export const ClassTitle = styled.h1`
  // border: solid black 1px;
  // background-color: #fff;
  max-width: 400px;
  height: auto;
  font: 700 18px Poppins, sans-serif;
  text-align: left; // Center text
  margin: 10px 0;
  // padding: 0 20px;
`;

export const ClassDetailsStyled = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 3fr; // Defines two columns: 1st column is 1/4th, 2nd column is 3/4ths of the total width
  // gap: 10px; // Gap between grid items
  width: 100%; //Makes the grid 80% of the width of the parent container
  padding-bottom: 10px;
`;

export const DetailItem = styled.div`
  display: contents; // Makes the container behave like a direct child of the grid container
`;

// export const DetailLabel = styled.span`
//   color: var(--Text-Muted, gray);
//   font-family: Poppins, sans-serif;
//   font-size: 14px;
//   font-weight: 600;
//   color: #eee;
// `;

export const DetailValue = styled.div`
  font-family: Lora, sans-serif;
  width:100%;
  color: black;
  font-weight: 500;
  font-size: 16px;
  // text-decoration: underline;
  font-style: italic;
`;

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px 12px 0 0;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 11px;
  gap: 20px;
  z-index: 1000;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // Very thin drop shadow
  @media (min-width: 500px) {
    width: 500px; /* Apply max-width constraint */
    left: calc((100vw - 500px) / 2);
    right: calc((100vw - 500px) / 2);
  }
`;

export const InputForm = styled.form`
  /* border: solid red 3px; */
  padding: 0px 20px 0px 20px;
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

export const InputBoxWrapper = styled.div`
  align-self: stretch;
  border-radius: var(--br-12xl-5);
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-mini) 0px var(--padding-base);
`;

export const InputBox = styled.input`
  height: 63px;
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
  color: var(--color-gray);
  text-align: left;
  display: flex;
  align-items: flex-start;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 var(--padding-mini);
  border-radius: var(--br-12xl-5);
  &:focus {
    outline: none;
  }
`;


export const Label = styled.div`
  font: 15px Lora, sans-serif;
  text-align: center; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  background-color:lightgrey;
  color:black;
  border-radius:2px;
  padding:2px 5px;

`;

export const OptionListPrice = styled.div`
	// width: 100%;
	// margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:800;
`;


export const Subheader = styled.div`
  font-weight: 700;
  font-size: 20px;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  margin-bottom:20px;
  // display:flex;
  // align-items:center;
  // justify-content:flex-start;
  border-left:10px solid rgb(0,0,139);
  width:100%;
  color:black;
  padding-left:20px;
  

`;


export const OptionListContainer = styled.div`
  position: relative;
	width: 100%;
	max-width: 978px;
	margin: 0px auto;
	padding: 5px 0;
	// margin-bottom: 15px;
	// border:10px solid blue;
	height: 220px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
      gap: 10px;
	background-color: none;
  overflow: auto !important; // enable horizontal scrolling
  // margin-bottom:20px;
  
  

	position: sticky; // Add this line
	// top: 0; // And this line
	z-index: 999;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


	// @media (max-width: 576px) {
	// 	margin: 0;
	// 	padding: 0px 0px 30px 0px;
	// }
`;

export const OptionList = styled.button`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
	// width: 300px;
	border: 2px solid rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 200px;
  border-radius: 50%;
	// height: 100%;
	background-color:#ffffff;
	color: rgba(0, 0, 0, 1);
	font-size: clamp(18px, 2vw, 2rem);
	min-width: 200px;
	padding: 10px 10px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	//Acts like an 'active' tab
	border: ${(props) =>
		props.$active
			? "3px solid rgba(255, 133, 89, 1)"
			: "2px solid rgba(0, 0, 0, 0.1)"};

	background-color:${(props) =>
		props.$active
			? "rgba(255, 133, 89, 0.3)"
			: "#ffffff"};

`;



export const DynamicallyChangeContent = styled.div`
  text-align: left; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  // background-color:rgba(0,0,0,0.5);
  color:black;
  // padding:2px 5px;
  width:100%;
    height:100%;
    position:absolute;
    

   ul {
    list-style-type: disc; // Use disc for bullet points
    padding-left: 20px; // Add some padding to indent the list
      font-size:18px;
    
  }

`;

export const DynamicallyChangeContentContainer =styled.div`
display: flex;
overflow: hidden; /* Add this to hide the components that are off-screen */
width: 100%;
background-color:rgba(	255, 242, 237,1);
  border-radius:15px;
  	padding: 10px 10px;
    // border:1px solid red;
    // min-height:100px;
    height:200px;
      position: relative;
  margin-top:20px;


`

export const OptionListTopContainer = styled.div`
	// flex: 2;
	display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
	// border: 1px solid blue;
`;

export const OptionListMiddleContainer = styled.div`
color:grey;
font-size:15px;
text-align:left;
	// border: 1px solid blue;
`;

export const OptionListIconContainer = styled.div`
	flex: 1;
	width: 100%;
	// border: 1px solid red;

	svg {
		width: 30%;
		fill: #ffffff;
	}
`;

export const OptionListTitle = styled.div`
	width: 100%;
	margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:600;
`;


export const ImageContainer = styled.div`
	width: 50%;
	margin: 10px auto;

img{

width:100%;

}
`;


export const StatusContainer = styled.div`
	padding: 10px;
	width: 100%;
	margin-bottom: 10px;
	color: white;
	text-align: center;
	background-color: ${(props) => {
		switch (props.status) {
			case 1:
				return "green";
			case 9:
				return "red";
			case 0:
				return "orange";
			default:
				return "teal";
		}
	}};
`;