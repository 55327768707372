// src/layouts/MainLayout.js
import React from "react";
import NavbarBottomC from "../components/NavbarBottom/NavbarBottomC";

const MainLayoutC = ({ children }) => {
  return (
    <>
      {children}
      <NavbarBottomC />
    </>
  );
};

export default MainLayoutC;
