//NavbarBottom.js
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavigationItems = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px 12px 0 0;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px 20px;
  gap: 20px;
  z-index: 1000;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // Very thin drop shadow
  @media (min-width: 500px) {
    width: 500px; /* Apply max-width constraint */
    left: calc((100vw - 500px) / 2);
    right: calc((100vw - 500px) / 2);
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none; // Removes underline from the link
  color: inherit; // Inherits color from parent or specify a color
  &:hover {
    color: #007bff; // Example hover color
  }
`;

const NavbarItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const Label = styled.div`
  margin-top: 0px;
  font-size: 10px;
`;

const NavbarBottom = () => {
  const navItems = [
    {
      icon: process.env.PUBLIC_URL + "/assets/homeIcon.svg",
      label: "Home",
      path: "/",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/icehockeyIcon.svg",
      label: "Bookings",
      path: "/bookings",
    },
    // {
    //   icon: process.env.PUBLIC_URL + "/assets/notificationIcon.svg",
    //   label: "Notifications",
    //   path: "/notifications",
    // },
    {
      icon: process.env.PUBLIC_URL + "/assets/usersIcon.svg",
      label: "Profile",
      path: "/users",
    },
  ];

  return (
    <NavigationItems>
      {navItems.map((item, index) => (
        <StyledLink to={item.path} key={index}>
          <NavbarItem>
            <Icon src={item.icon} alt={item.label} />
            <Label>{item.label}</Label>
          </NavbarItem>
        </StyledLink>
      ))}
    </NavigationItems>
  );
};

export default NavbarBottom;
