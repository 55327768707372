// React component imports
import React, { useState } from "react";
import styled from "styled-components";
import ButtonUpdate from "../../components/ButtonMobile/ButtonUpdate";

import { TitleHeader } from "../../globalStyles";

// Adjusted DetailValue to conditionally render input or text
export const DetailValue = ({ value, isEditable, updateValue }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (updateValue) {
      updateValue(newValue); // Now correctly calls updateValue with the new value
    }
  };

  if (isEditable) {
    return (
      <StyledInput type="text" value={inputValue} onChange={handleChange} />
    );
  } else {
    return (
      <span
        style={{
          fontFamily: "Lora, sans-serif",
          color: "#0c729e",
          fontWeight: 500,
          fontSize: "16px",
          textDecoration: "underline",
          fontStyle: "italic",
        }}
      >
        {value}
      </span>
    );
  }
};

export const ClassCard = ({ classInfo, isEditable, updateValue }) => {
  const handleImageUpload = () => {
    console.log("Image upload function here"); // Implement your image upload logic
  };

  return (
    <CardContent>
      <ClassInfoWrapper>
        <DetailsGrid>
          {classInfo.details
            .filter((detail) => typeof detail === "object")
            .map((detail, index) => (
              <React.Fragment key={index}>
                {detail.imagePath ? (
                  <ImageWrapper>
                    <CircleImage src={detail.imagePath} alt="" />
                    {isEditable && (
                      <CameraIcon onClick={handleImageUpload}>
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.5 20C6.93125 20 4.14375 13.2675 8.08125 9.33125C12.0188 5.395 18.75 8.18125 18.75 13.75C18.75 15.4076 18.0915 16.9973 16.9194 18.1694C15.7473 19.3415 14.1576 20 12.5 20ZM8.025 3.2L7.1875 4C6.725 4.44625 6.10625 5.01 5.4625 5.0125H2.5C1.1425 5 0 5.89 0 7.2V20.3C0 21.5875 1.25 22.5 2.5 22.5H22.5C23.75 22.5 25 21.605 25 20.3V7.2C25 5.90375 23.75 5 22.5 5H19.5375C18.8937 4.9975 18.275 4.43375 17.8125 3.9875L16.975 3.1875C16.5096 2.74536 15.8919 2.4992 15.25 2.5H9.75C9.10633 2.50257 8.48844 2.7533 8.025 3.2ZM12.5 17.5C14.5711 17.5 16.25 15.8211 16.25 13.75C16.25 11.6789 14.5711 10 12.5 10C10.4289 10 8.75 11.6789 8.75 13.75C8.75 15.8211 10.4289 17.5 12.5 17.5Z"
                            fill="#334B7B"
                            fill-opacity="0.7"
                          />
                          <path
                            d="M12.5 17.5C14.5711 17.5 16.25 15.8211 16.25 13.75C16.25 11.6789 14.5711 10 12.5 10C10.4289 10 8.75 11.6789 8.75 13.75C8.75 15.8211 10.4289 17.5 12.5 17.5Z"
                            fill="#334B7B"
                            fill-opacity="0.7"
                          />
                        </svg>
                      </CameraIcon>
                    )}
                  </ImageWrapper>
                ) : (
                  <>
                    <DetailLabel style={{ gridArea: `label${index + 1}` }}>
                      {detail.label}
                    </DetailLabel>

                    <DetailValue
                      value={detail.value}
                      isEditable={isEditable}
                      updateValue={(newValue) =>
                        updateValue(detail.label, newValue)
                      } // Pass a function to update global state
                    />
                  </>
                )}
              </React.Fragment>
            ))}
        </DetailsGrid>
      </ClassInfoWrapper>
    </CardContent>
  );
};

export const MyProfileUpdate = () => {
  const [formData, setFormData] = useState({});

  const updateValue = (label, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [label]: value, // Assuming label is unique, update the corresponding value
    }));
  };

  const classData = [
    {
      details: [
        {
          label: "NAME",
          value: "Ben Chan",
        },
        {
          label: "CONTACT",
          value: "99990000",
        },
        { label: "EMAIL", value: "hello@hello.com" },
        { label: "PASSWORD", value: "**********" },

        { imagePath: "/assets/ChanBen.jpg" },
      ],
    },
    {
      details: [
        {
          label: "CHILD",
          value: "Peter Chan",
        },
        {
          label: "BIRTH",
          value: "08/08/2015",
        },
        { label: "STUDENT ID", value: "004567" },
        { imagePath: "/assets/ChanPeter.jpg" },
      ],
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data to send to backend:", formData);
    // Replace with actual submission logic
  };

  return (
    <>
      <TitleHeader>Update Profile</TitleHeader>
      <form onSubmit={handleSubmit}>
        {classData.map((classInfo, index) => (
          <ClassCard
            key={index}
            classInfo={classInfo}
            isEditable={true}
            updateValue={updateValue}
          />
        ))}
        {/* <ButtonContainer>
        <ButtonUpdate
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          status="uploadedMessage"
        />
      </ButtonContainer> */}

        <ButtonContainer>
          <ButtonUpdate
            onClick={() => console.log("Update clicked")}
            status="uploadedMessage"
          />
        </ButtonContainer>
      </form>
    </>
  );
};
export default MyProfileUpdate;

// Styled-components for layout
export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
`;

export const ClassInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid #fff;
  border-radius: 0 0 20px 20px;
  width: 95%;
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr; /* Adjusted column widths */
  grid-template-rows: repeat(3, auto); /* Keep the three rows */
  grid-template-areas:
    "label1 value1 image"
    "label2 value2 image"
    "label3 value3 image"
    "label4 value4 image";
  gap: 10px;
  width: 100%;
  align-items: start;
`;

export const DetailLabel = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0c729e;
`;

export const CircleImage = styled.img`
  border: solid #0c729e 3px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

// const ImageWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%; /* Ensure the wrapper takes full height of the grid area */
//   grid-area: image; /* Assign the grid area directly in the styled component */
// `;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Adjust the gap between buttons as needed */
`;

// Styled input component remains unchanged
const StyledInput = styled.input`
  font-family: Lora, sans-serif;
  color: #0c729e;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  font-style: italic;
  padding: 5px;
  width: 90%; // Adjust as needed
  border: 1px solid #ccc; // Added border for visibility
  background: #fff; // Added background color
  &:focus {
    outline: none;
    border-color: #0c729e; // Highlight on focus
  }
`;

// Adjusted ImageWrapper to include the camera icon
const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the wrapper takes full height of the grid area */
  grid-area: image; /* Assign the grid area directly in the styled component */
`;

const CameraIcon = styled.div`
  position: absolute;
  border: 0.5px solid black;
  opacity: 0.9;
  background-color: #efefef;
  padding: 10px;
  border-radius: 50%;
  top: 0;
  right: 0;
  cursor: pointer;
  svg {
    width: 25px;
    height: 25px;
    fill: #334b7b;
    fill-opacity: 0.7;
  }
`;
