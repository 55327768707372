import React, { useEffect, useRef } from "react";
import { useButton } from "react-aria";
import { useAnimation } from "framer-motion";
import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 90px;
  padding: 0 10px;
  border-radius: 10px;
`;

export const TitleMessage = styled.h1`
  font: 400 18px Lora, sans-serif;
  text-align: center;
  margin-bottom: 0px;
  color: #0c729e;
  font-style: italic;
  padding: 0 10px;
`;

export const MergedButtonWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 90px;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  background-color: "#657F80";
`;

export const AddIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM16.25 12.75H12.75V16.25C12.75 16.6645 12.414 17 12 17C11.586 17 11.25 16.6645 11.25 16.25V12.75H7.75C7.336 12.75 7 12.4145 7 12C7 11.5855 7.336 11.25 7.75 11.25H11.25V7.75C11.25 7.3355 11.586 7 12 7C12.414 7 12.75 7.3355 12.75 7.75V11.25H16.25C16.664 11.25 17 11.5855 17 12C17 12.4145 16.664 12.75 16.25 12.75Z"
      fill="#637394"
    />
  </svg>
);

export const CameraIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 20C6.93125 20 4.14375 13.2675 8.08125 9.33125C12.0188 5.395 18.75 8.18125 18.75 13.75C18.75 15.4076 18.0915 16.9973 16.9194 18.1694C15.7473 19.3415 14.1576 20 12.5 20ZM8.025 3.2L7.1875 4C6.725 4.44625 6.10625 5.01 5.4625 5.0125H2.5C1.1425 5 0 5.89 0 7.2V20.3C0 21.5875 1.25 22.5 2.5 22.5H22.5C23.75 22.5 25 21.605 25 20.3V7.2C25 5.90375 23.75 5 22.5 5H19.5375C18.8937 4.9975 18.275 4.43375 17.8125 3.9875L16.975 3.1875C16.5096 2.74536 15.8919 2.4992 15.25 2.5H9.75C9.10633 2.50257 8.48844 2.7533 8.025 3.2Z"
      fill="#334B7B"
      fill-opacity="0.7"
    />
    <path
      d="M12.5 17.5C14.5711 17.5 16.25 15.8211 16.25 13.75C16.25 11.6789 14.5711 10 12.5 10C10.4289 10 8.75 11.6789 8.75 13.75C8.75 15.8211 10.4289 17.5 12.5 17.5Z"
      fill="#334B7B"
      fill-opacity="0.7"
    />
  </svg>
);

const ButtonUploadPayment = ({ onClick, status }) => {
  let controls = useAnimation();
  let ref = useRef();

  useEffect(() => {
    // Set the initial background color when the component mounts
    controls.start({ background: "#fff" });
  }, [controls]);

  let { buttonProps } = useButton(
    {
      onPressStart: () => {
        controls.stop();
        controls.set({ background: "#91b5b7" });
      },
      onPressEnd: () => {
        controls.start({
          background: "#fff",
          transition: { duration: 0.65 },
        });
      },
      onPress: onClick,
    },
    ref
  );

  // Determine the message based on the status prop
  const messages = {
    uploadedMessage: ["Upload Photo of payment proof"], //ENGLISH
    otherMessage: ["上傳付款證明照片"], //CHINESE
  };

  const children = messages[status] || []; // Default to an empty array if status is unknown

  return (
    <MergedButtonWrapper animate={controls} {...buttonProps}>
      <AddIcon />
      {/* Render children dynamically */}
      {children.map((message, index) => (
        <TitleMessage key={index}>{message}</TitleMessage>
      ))}
      <CameraIcon />
    </MergedButtonWrapper>
  );
};

export default ButtonUploadPayment;
