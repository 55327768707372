import React from "react";
import { TitleHeaderC } from "../../globalStyles"; //CHINESE TITLE
import NotificationStatusC from "./NotificationStatusC";
const NotificationsC = () => {
  return (
    <div>
      <TitleHeaderC>通知</TitleHeaderC>
      <NotificationStatusC />
    </div>
  );
};

export default NotificationsC;
