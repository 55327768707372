// React component imports
import React from "react";
import styled from "styled-components";
import ButtonAddChild from "../../components/ButtonMobile/ButtonAddChild";
import ButtonEdit from "../../components/ButtonMobile/ButtonEdit";
import ButtonLogout from "../../components/ButtonMobile/ButtonLogout";

export const ClassCard = ({ classInfo }) => {
  return (
    <>
      <CardContent>
        <ClassInfoWrapper>
          <DetailsGrid>
            {/* Render label-value pairs */}
            {classInfo.details
              .filter(
                (detail) => typeof detail === "object" && !detail.imagePath
              )
              .map((detail, index) => (
                <>
                  <DetailLabel style={{ gridArea: `label${index + 1}` }}>
                    {detail.label}
                  </DetailLabel>
                  <DetailValue style={{ gridArea: `value${index + 1}` }}>
                    {detail.value}
                  </DetailValue>
                </>
              ))}
            {/* Render image aligned to the right side of its container */}
            {classInfo.details
              .filter((detail) => detail.imagePath)
              .map((detail, index) => (
                <ImageWrapper key={index}>
                  <CircleImage src={detail.imagePath} alt="" />
                </ImageWrapper>
              ))}
          </DetailsGrid>
        </ClassInfoWrapper>
      </CardContent>
    </>
  );
};

export const MyProfileC = () => {
  const classData = [
    {
       details: [
        {
          label: "姓名",
          value: "Ben Chan",
        },
        {
          label: "聯絡電話",
          value: "99990000",
        },
        { label: "電郵", value: "hello@hello.com" },
        { imagePath: "/assets/ChanBen.jpg" },
      ],
    },
    {
      details: [
        {
          label: "學生姓名",
          value: "Peter Chan",
        },
        {
          label: "出生年月日",
          value: "2015年8月8日",
        },
        { label: "學生ID", value: "004567" },
        { imagePath: "/assets/ChanPeter.jpg" },
      ],
    },
  ];

  return (
    <>
      {classData.map((classInfo, index) => (
        <ClassCard key={index} classInfo={classInfo} />
      ))}

      <ButtonContainer>
        <ButtonAddChild
          onClick={() => console.log("Button clicked")}
          status="uploadedMessage"
        />
        <ButtonEdit
          onClick={() => console.log("Button clicked")}
          status="uploadedMessage"
        />
        <ButtonLogout
          onClick={() => console.log("Button clicked")}
          status="uploadedMessage"
        />
      </ButtonContainer>
    </>
  );
};
export default MyProfileC;

// Styled-components for layout
export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
`;

export const ClassInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid #fff;
  border-radius: 0 0 20px 20px;
  width: 95%;
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr; /* Adjusted column widths */
  grid-template-rows: repeat(3, auto); /* Keep the three rows */
  grid-template-areas:
    "label1 value1 image"
    "label2 value2 image"
    "label3 value3 image";
  gap: 10px;
  width: 100%;
  align-items: start;
`;

export const DetailLabel = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0c729e;
`;

export const DetailValue = styled.span`
  font-family: Lora, sans-serif;
  color: #0c729e;
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  font-style: italic;
`;

export const CircleImage = styled.img`
  border: solid #0c729e 3px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

// New styled component for wrapping the image
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the wrapper takes full height of the grid area */
  grid-area: image; /* Assign the grid area directly in the styled component */
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Adjust the gap between buttons as needed */
`;
