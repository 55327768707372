import React from "react";
import { TitleHeader } from "../../globalStyles"; //ENGLISH TITLE
import MyProfile from "./MyProfile";
const Users = () => {
  return (
    <>
      <MyProfile />
    </>
  );
};

export default Users;