import React from "react";
import { TitleHeader } from "../../globalStyles"; //ENGLISH TITLE
// import { TitleHeaderC } from "../../globalStyles";//CHINESE TITLE
import BookingApproved from "./BookingApproved";

const Bookings = () => {
  return (
    <>
      <TitleHeader>Bookings</TitleHeader>
      <BookingApproved />
    </>
  );
};

export default Bookings;
