import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { ModalBackground, ModalContent } from "../../styles/Admin";
import { CrossX } from "../../assets/IconSVG.js";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
`;

export const ModalClose = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  bottom: 0;
  position: fixed;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  bottom: 0;
  position: fixed;
  height: 100%;
`;

// Top bar

export const TopBarWrapper = styled.div`
  background-color: #1b2631;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  height: 80px;
  // position: fixed;
  top: 0; /* Stick to the Top */
  z-index: 99999 !important;
  // box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.2);
  user-select: none; //Prevent text selection
  overflow: hidden;
  margin: auto;
  color: #ffffff;
  font-size: 20px;
  font0weight: 400;
  // border: 1px solid red;

  @media (max-width: 480px) {
    height: 50px;
    padding: 2px 0;
  }
`;

export const STickyTopBarWrapper = styled.div`
  background-color: #1b2631;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0; /* Stick to the Top */
  z-index: 999 !important;
  // box-shadow: 0px 0px 3px 5px rgba(0, 0, 0, 0.2);
  user-select: none; //Prevent text selection
  // overflow: hidden;
  // margin: auto;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  // border: 1px solid red;

  @media (max-width: 480px) {
    height: 50px;
    padding: 2px 0;
  }
`;

export const TopNavBar = styled.nav`
  max-width: 978px; /* Default maximum width */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > * {
    // flex: 1;
    // border: 1px solid red;
  }
`;

export const TopNavLeft = styled.div`
  svg {
    fill: black;
  }
`;

export const TopNavCenter = styled.div`
  text-align: left;
  margin: 0;
//   border: 1px solid red;

//   h1 {
//     display: block;
//     // font-size: clamp(1rem, 3vw, 2.125rem);
//     text-transform: uppercase;
//     color: #000000;
//     letter-spacing: 1px;
//     // font-family: serif;
//     // font-weight: 400;
//     margin: 0;
//     br {
//       display: none;
//     }
//     @media (max-width: 480px) {
//       font-size: 12px;
//       br {
//         display: none;
//       }
//     }
    span {
      margin-top: 0px;
      font-size: clamp(0.8rem, 1vw, 1rem);
      color: #eaf2f8;
      word-spacing: 1px;
      font-weight: normal;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: sans-serif;
      font-weight: 500;
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      grid-template-rows: 20px 0;
      grid-gap: clamp(2px, 1vw, 10px); /* Adjust as needed */
      align-items: center;
      white-space: nowrap;
      @media (max-width: 480px) {
        font-size: 8px;
        letter-spacing: 0.5px;
      }
      &:after,
      &:before {
        content: " ";
        display: block;
        border-bottom: 1px solid #aeb6bf;
        border-top: 1px solid #aeb6bf;
        height: clamp(2px, 1vw, 1px); /* Adjust as needed */

        background-color: #1b2631;
      }
    }
  }
`;

export const TopNavRight = styled.div`
  font-size: clamp(10px, 2vw, 20px);
  color: #d5dbdb;
  font-family: serif;
  margin: 0;
  text-align: right;

  svg {
    fill: #000000;
    width: 20px;
  }
`;

export const Subheader = styled.div`
  font-weight: 700;
  font-size: 20px;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  margin-bottom: 20px;
  // display:flex;
  // align-items:center;
  // justify-content:flex-start;
  border-left: 10px solid rgb(0, 0, 139);
  width: 100%;
  color: black;
  padding-left: 20px;
`;

export const FormModal = ({
  isOpen,
  title,
  children,
  autoClose,
  handleClose,
}) => {
  const [modalOpen, setModalOpen] = useState(isOpen);

  // Effect to sync internal state with the isOpen prop
  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    let timer;
    if (autoClose && modalOpen) {
      timer = setTimeout(() => {
        setModalOpen(false);
      }, 5000);
    }

    // Cleanup the timer when the component unmounts or modal state changes
    return () => clearTimeout(timer);
  }, [autoClose, modalOpen]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (modalOpen && handleClose) {
      handleClose();
    }
  };

  if (!modalOpen) return null;

  return (
    <ModalBackground onClick={toggleModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <TopNavBar>
          <TopNavCenter>
            <Subheader>Add member</Subheader>
          </TopNavCenter>
          <TopNavRight>
            {!autoClose && <CrossX onClick={toggleModal} />}
          </TopNavRight>
        </TopNavBar>
        {children}
      </ModalContent>
    </ModalBackground>
  );
};

export default FormModal;
