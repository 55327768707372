import { useRef } from "react";
// import { ButtonTouch } from "../../globalStyles";
import { useButton } from "react-aria";
import { useAnimation } from "framer-motion";
import styled from "styled-components";
import { motion } from "framer-motion"; //For ButtonTouch
//BUTTON iOS Touch Style
import { useNavigate } from 'react-router-dom';


export const ButtonTouch = styled(motion.button)`
  /**********************************/
  // CAN CUSTOMISE BELOW CSS:
  /**********************************/
  border-radius: 50%;
  width: 35px;
  height: 35px;
  align-items: center; //Center Text

  background-color: #f3f6f4; //Must be same as controls.start({ background: "#657F80",

  /**********************************/
  // DO NOT CHANGE BELOW CSS!!!
  /**********************************/
  // Solving button issues:
  user-select: none; //Prevent Text Selection
  touch-action: none; //Prevent Scrolling on long press & drag;
  -webkit-tap-highlight-color: "transparent"; //required for iOS touch effect
  //Frontend Design of Button:
  border: none; //No border for button
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  outline: none;
  overflow: hidden;
  z-index: 1 !important;
  cursor: pointer;

  :focus {
    outline: none; //Required to lose ring on mobile
  }
`;

//Component
const ButtonBack = ({ onClick, children }) => {
  // Set up animation controls using Framer Motion
  let controls = useAnimation();
  // Set up a reference to the button element
  let ref = useRef();

  // Set up buttonProps for use with React Aria
  let { buttonProps } = useButton(
    {
      ////////////////////////////////////////////////////////
      // Setting button to highlight color via onPressStart //
      ///////////////////////////////////////////////////////
      onPressStart: () => {
        controls.stop(); //Cancels any current animation running
        controls.set({ background: "#91b5b7" }); //Highlight Color onPress of
      },

      //////////////////////////////
      // Animate button on release
      /////////////////////////////
      onPressEnd: () => {
        controls.start({
          background: "#f3f6f4", //Color when inactive *Must be same as background color!!!
          transition: { duration: 0.65 }, //Trigger motion animation
        });
      },

      /////////////////////////////
      // Map onClick to onPress //
      ////////////////////////////
      onPress: onClick,
    },
    ref
  );

  // Render the button with FocusRing and ButtonTouch components
  return (
    // <FocusRing FocusRingStyles>
    <ButtonTouch animate={controls} {...buttonProps}>
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29 12.6692V16.3308H7.0303L17.0997 26.4003L14.5 29L0 14.5L14.5 0L17.0997 2.59975L7.0303 12.6692H29Z"
          fill="#888888"
        />
      </svg>
      {/* Button Text */}
      {children}
    </ButtonTouch>
    // </FocusRing>
  );
};

export default ButtonBack;

//SAMPLE STYLED-COMPONENTS on other files from ClassDetails.js:
// a. in the parent, make sure to have position: relative
// b. create a ButtonWrapper and position it absolutely

// export const CardContent = styled.div`
//   position: relative; // Establishes a new positioning context
//   display: flex;
//   flex-direction: column;
//   align-items: center; // Center children horizontally
//   width: 100%;
//   min-height: 100vh; // Adjust based on your layout needs
// `;

// export const ButtonWrapper = styled.div`
//   position: absolute;
//   top: 22px;
//   left: 22px;
//   z-index: 10;
// `;