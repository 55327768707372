import * as React from "react";
import styled from "styled-components";
export function CheckCircle(props) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="1.5em"
      width="1.5em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-3.97-3.03a.75.75 0 00-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 00-1.06 1.06L6.97 11.03a.75.75 0 001.079-.02l3.992-4.99a.75.75 0 00-.01-1.05z" />
    </svg>
  );
}

// Styled component for the container div
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15em;
  height: 5em;
  background-color: green;
  padding: 0px;
  box-sizing: border-box;
  color: white;
  border-radius: 3px;
`;

// Styled component for the text span
const Text = styled.span`
   font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: bold;
`;

const Approved = () => {
  return (
    <Container>
      <CheckCircle />
      <Text>Confirmed</Text>
    </Container>
  );
};

export default Approved;
