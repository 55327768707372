import { useRef } from "react";
// import { ButtonTouch } from "../../globalStyles";
import { useButton } from "react-aria";
import { useAnimation } from "framer-motion";
import styled from "styled-components";
import { motion } from "framer-motion"; //For ButtonTouch
//BUTTON iOS Touch Style

export const ButtonTouch = styled(motion.button)`
  /**********************************/
  // CAN CUSTOMISE BELOW CSS:
  /**********************************/
  color: white; //Text Color
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  text-transform: uppercase;
  // width: 250px;
  border-radius: 10px;
  align-items: center; //Center Text

  background-color: #657f80; //Must be same as controls.start({ background:

  /**********************************/
  // DO NOT CHANGE BELOW CSS!!!
  /**********************************/
  // Solving button issues:
  user-select: none; //Prevent Text Selection
  touch-action: none; //Prevent Scrolling on long press & drag;
  -webkit-tap-highlight-color: "transparent"; //required for iOS touch effect
  //Frontend Design of Button:
  border: none; //No border for button
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  outline: none;
  overflow: hidden;
  z-index: 1 !important;
  cursor: pointer;

  :focus {
    outline: none; //Required to lose ring on mobile

    &:nth-child(3) {
      order: 3;
    }
  }
`;

//Component
const ButtonMobile = ({ onClick, children }) => {
  // Set up animation controls using Framer Motion
  let controls = useAnimation();
  // Set up a reference to the button element
  let ref = useRef();

  // Set up buttonProps for use with React Aria
  let { buttonProps } = useButton(
    {
      ////////////////////////////////////////////////////////
      // Setting button to highlight color via onPressStart //
      ///////////////////////////////////////////////////////
      onPressStart: () => {
        controls.stop(); //Cancels any current animation running
        controls.set({ background: "#91b5b7" }); //Highlight Color onPress of
      },

      //////////////////////////////
      // Animate button on release
      /////////////////////////////
      onPressEnd: () => {
        controls.start({
          background: "#657F80", //Color when inactive *Must be same as background color!!!
          transition: { duration: 0.65 }, //Trigger motion animation
        });
      },

      /////////////////////////////
      // Map onClick to onPress //
      ////////////////////////////
      onPress: onClick,
    },
    ref
  );

  // Render the button with FocusRing and ButtonTouch components
  return (
    // <FocusRing FocusRingStyles>
    <ButtonTouch animate={controls} {...buttonProps}>
      {children}
    </ButtonTouch>
    // </FocusRing>
  );
};

export default ButtonMobile;
