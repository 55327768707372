import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import { keyframes } from "styled-components";
const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap');

  
  :root {
    /* Existing variables */
    --color-white: #FFFFFF; /* Or any shade of white you prefer */
    --font-poppins: 'Poppins', sans-serif;
    --font-lora: 'Lora', serif;
    --font-noto-sans-tc: 'Noto Sans TC', sans-serif; /* Chinese */
    
    /* font sizes */
    --font-size-sm: 14px;
    --font-size-lg: 18px;
    /* Colors */
    --color-steelblue-100: #0c729e;
    --text-main: #fff;
    --color-lightsteelblue: #9db2ce;
    --color-gray-100: #1a2232;
    --surface-glass: rgba(31, 41, 61, 0.7);
    --text-muted: #637394;

/* Login Template Colors */
--color-gray: #241c1c;
--color-silver: rgba(200, 196, 183, 0.72);
--color-whitesmoke: #f5f4f2;

/* Paddings */
--padding-6xl: 25px;
--padding-23xl: 42px;
--padding-24xl: 43px;
--padding-3xs: 10px;
--padding-xl: 20px;
--padding-mini: 15px;
--padding-base: 16px;

/* Border radiuses */
--br-12xl-5: 31.5px;

    /* Gaps */
    --gap-base: 16px;
    --gap-5xs: 8px;

    /* Paddings */
    --padding-base: 16px;
    --padding-5xs: 8px;
    --padding-29xl: 48px;

  }
  
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
  html {
      font-size: 62.5%; // Setting base font size to 10px for easier rem calculations
      background-color: #ddd;
  }
  body {
      font-family: var(--font-poppins), var(--font-lora), var(--font-noto-sans-tc), sans-serif;
      line-height: 1.6;
      color: #333;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow-x: hidden; // Prevent horizontal scrolling
      margin: 0 auto; // Center the content
      min-width: 300px; /* Minimum width constraint */
      max-width: 500px; /* Maximum width constraint */
      width: 100%; /* Ensures the body takes full width up to the max-width */
      min-height: 100vh;
      padding-bottom: 80px;
      box-sizing: border-box;
      background: linear-gradient(#d9e7ff 0%, #e3ffe7 100%);//Background for all pages!!!

  }
  h1, h2, h3, h4, h5, h6 {
      margin-bottom: 20px;
  }

  p {
      margin-bottom: 10px;
  }

  ul, ol {
      list-style-position: inside;
  }

  a {
      text-decoration: none;
      color: #007bff;
      transition: color 0.3s ease;

      &:hover {
          color: darken(#007bff, 15%);
      }
  }
  input, textarea {
      outline: none;
      border-radius: 0;
      border: 1px solid #ccc;
      padding: 8px;
      font-size: 1rem;
      resize: none;
  }

  img {
      max-width: 100%;
      height: auto;
  }
`;

export const TitleHeader = styled.div`
  font: 800 25px var(--font-poppins), sans-serif;
  text-align: center; // Center text
  margin-top: 20px;
  margin-bottom: 20px; // Adjust spacing after title
  color: #0c729e;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`;

export const TitleHeaderC = styled.div`
  font: 800 25px var(--font-noto-sans-tc), sans-serif;
  text-align: center; // Center text
  margin-top: 20px;
  margin-bottom: 20px; // Adjust spacing after title
  color: #0c729e;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`;

////////////////////////////////////////////
// LOADING CIRCLE //
////////////////////////////////////////////
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingOverlay = styled.div`
  z-index: 99999999999999999999 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 2s linear infinite;
`;



export default GlobalStyles;