
import React from "react";
import styled from "styled-components";

const ExcusedIconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
`;

const Icon = styled.svg`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

const ExcusedIcon = () => (
  <ExcusedIconWrapper>
    <Icon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="#727272" />
      <circle cx="6" cy="12" r="1.5" fill="white" />
      <circle cx="12" cy="12" r="1.5" fill="white" />
      <circle cx="18" cy="12" r="1.5" fill="white" />
    </Icon>
    <span>Excused</span>
  </ExcusedIconWrapper>
);

export default ExcusedIcon;
