import * as React from "react";
import styled from "styled-components";

export function Clock(props) {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="orange"
      height="5em"
      width="5em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm176.5 585.7l-28.6 39a7.99 7.99 0 01-11.2 1.7L483.3 569.8a7.92 7.92 0 01-3.3-6.5V288c0-4.4 3.6-8 8-8h48.1c4.4 0 8 3.6 8 8v247.5l142.6 103.1c3.6 2.5 4.4 7.5 1.8 11.1z" />
    </svg>
  );
}

// Styled component for the container div
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15em;
  height: 5em;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 1em;
  border: 1px solid orange;
  background-color: yellow;
  color: orange;
`;

// Styled component for the text span
const Text = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-left: 0em;
  padding-right: 1em;
`;

const Pending = () => {
  return (
    <Container>
      <Clock />
      <Text>Pending</Text>
    </Container>
  );
};

export default Pending;
