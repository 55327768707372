import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";

// Styled components
const Container = styled.div`
  padding: 20px;
  background-color: #f4f4f4;
  font-size: 14px;
  width: 100%;
`;

const Dropdown = styled.select`
  width: 100%;
  padding: 2vw;
  margin-bottom: 2vw;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-appearance: none; /* Removes default browser styling */
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'); /* Adds custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 1rem center; /* Positions the custom dropdown arrow */
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.timeslot
      ? "repeat(auto-fill, minmax(70px, 1fr));"
      : "repeat(auto-fit, minmax(70px, 1fr))"};
  gap: 5px;
  width: 100%; /* Ensure the grid container takes the full width */

  // max-width: 100%; /* Ensure the grid takes the full width available */
  // border: 1px solid red;
`;

const TimeslotButton = styled.button`
  position: relative;
  width: 100% !important;
  padding: 10px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: ${(props) =>
    props.selected
      ? "3px solid rgba(255, 133, 89, 1)"
      : "2px solid rgba(0, 0, 0, 0.1)"};
  background-color: ${(props) =>
    props.selected ? "rgba(255, 133, 89, 0.3)" : "#ffffff"};
  color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  cursor: ${(props) => (props.available ? "pointer" : "default")};
  &:hover {
    background-color: ${(props) =>
      props.selected ? "rgba(255, 133, 89, 0.3)" : "#ffffff"};
  }

  &.full {
    background-color: lightgrey;
  }

  &.full::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top right, transparent 50%, grey 50%);
    opacity: 0.5;
    box-sizing: border-box;
  }
`;

const Remark = styled.div`
  font-size: 10px;
  color: red;
`;

const DateAndTimeBooking = ({
  setActiveCourse,
  activeCourse,
  courses,
  program,
  errorFields,
  language,
}) => {
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [availableOption2, setAvailableOption2] = useState([]);

  useEffect(() => {
    if (activeCourse?.option1value) {
      setSelectedOption1(
        activeCourse?.option1value ? activeCourse.option1value : null
      );
    }
  }, [activeCourse]);

  // Simulate 21 dates starting from today
  const generateOption1Values = () => {
    // const dates = [];
    // const today = new Date();
    // for (let i = 0; i < 21; i++) {
    //   const futureDate = new Date(
    //     today.getFullYear(),
    //     today.getMonth(),
    //     today.getDate() + i
    //   );
    //   dates.push(futureDate.toISOString().slice(0, 10)); // YYYY-MM-DD format
    // }

    const option1values = courses?.map((course) => {
      const option1value = course?.option1value;
      return option1value;
    });

    const uniqueOption1Values = Array.from(new Set(option1values));
    return uniqueOption1Values;
  };

  const option1values = generateOption1Values();

  console.log(program);
  // console.log(errorFields);

  // Function to check if a string is in HH:MM time format
  const isTimeFormat = (value) => {
    const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timeFormatRegex.test(value);
  };

  // Simulate timeslot availability
  useEffect(() => {
    const simulateTimeslotAvailability = () => {
      // Filter the courses for the selected date
      const coursesForSelectedOption1 = courses.filter(
        (course) => course?.option1value === selectedOption1
      );

      // Map the courses to their times and filter out the unavailable ones
      const availableCourses = coursesForSelectedOption1.filter(
        (course) => course.open === 1
      ); // replace 'available' with the actual value indicating availability

      return availableCourses;
    };

    if (activeCourse?.option1value || selectedOption1) {
      const availableTimes = simulateTimeslotAvailability();
      setAvailableOption2(availableTimes);

      console.log(availableTimes);

      // Set the first available timeslot as selected and set the active course
      const firstAvailableTimeslot = availableTimes.find(
        (time) => time.availability > 0
      );

      if (firstAvailableTimeslot) {
        setActiveCourse(firstAvailableTimeslot);
      } else if (activeCourse !== null) {
        setActiveCourse(null);
        console.log("No available timeslots");
      }
    }
  }, [selectedOption1]);

  return (
    <Container>
      {/* <h2>Book Your Workshop</h2> */}
      <Dropdown
        value={selectedOption1}
        onChange={(e) => setSelectedOption1(e.target.value)}
      >
        <option value="">Select {program.option1label}</option>
        {option1values.map((option1, index) => (
          <option key={index} value={option1}>
            {option1}
          </option>
        ))}
      </Dropdown>

      {/* {errorFields.option2Value && "*"} */}
      {selectedOption1 && (
        <Grid timeslot={program.option2label === "Time"}>
          {availableOption2.map((time, index) => {
            const isAvailable = time.availability > 0;

            return (
              <TimeslotButton
                key={index}
                available={isAvailable}
                selected={activeCourse?.option2value === time.option2value}
                onClick={() => isAvailable && setActiveCourse(time)}
                className={!isAvailable ? "full" : ""}
              >
                {language === "en"
                  ? time.option2value
                  : time.option2value_chi || time.option2value}
                {isAvailable ? (
                  time.availability < 5 && (
                    <Remark>{time.availability} spots left</Remark>
                  )
                ) : (
                  <Remark>Full</Remark>
                )}
              </TimeslotButton>
            );
          })}
        </Grid>
      )}

      {/* {selectedTime && (
        <p>
          Selected: {selectedDate} at {selectedTime}
        </p>
      )} */}
    </Container>
  );
};

export default DateAndTimeBooking;
